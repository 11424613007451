import React, {Component} from 'react';
import {Geo2} from "./Geo2";
import {useTranslation} from "react-i18next";
import ReCaptchaV2 from "react-google-recaptcha";
import {REACT_APP_SITE_KEY} from "../Constants";
import {Form} from "formik";
import {toast,ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessageService from "../api/MessageService";
import ct from "../../src/contact_timetable.jpg"
class Contacts extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            isVerified:false
        }

    }
    leaveMessage=(event)=>{
        const{t}=this.props.translation
        event.preventDefault()
        toast.configure()
        if(this.state.content!=='')
        {
            if(this.state.isVerified)
            {
                let messageRequest = {
                    'content': this.state.message
                }
                MessageService.leaveMessage(messageRequest).then((res) => {
                    toast.success(t("xabarMJ"))
                    setTimeout(function () {
                        window.location.reload()
                    },2000)
                })
            }
            else {
                toast.warning(t("captchaniT"))
            }
        }
        else {
            toast.warning(t("xabarniKiriting"))
        }
    }
    verifyCallback(response)
    {
        if(response)
            this.setState({
                isVerified:true
            })

    }
    expiredCallback()
    {

        this.setState(
            {
                isVerified:false
            }
        )


    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    speak2=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }


    render(){
        const{t}=this.props.translation
        // const {name, email, subject, message, emailStatus} = this.state;
        return(
            <section className="contact_info_area sec_pad bg_color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="contact_info_item">
                                <h6 onClick={this.speak} className="f_p f_size_20 t_color3 f_500 mb_20">{t('addressHeader')}</h6>
                                <p onClick={this.speak} className="f_400 f_size_15">{t('addressContent')}</p>
                            </div>
                            <div><Geo2/></div>
                            <div className="contact_info_item">
                                <h6 onClick={this.speak} className="f_p f_size_20 t_color3 f_500 mb_20">{t('contacts')}</h6>
                                <p onClick={this.speak} className="f_400 f_size_15"><span className="f_400 t_color3">Tel:</span> 79-220-63-69</p>
                                <p onClick={this.speak} className="f_400 f_size_15"><span className="f_400 t_color3">Faks:</span> 79-220-63-66</p>
                                <p onClick={this.speak} className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span> navoiy@uzavtoyul.uz</p>
                            </div>
                        </div>
                        <div className="contact_form col-lg-9">
                            <h2 onClick={this.speak}className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">{t('xabarQoldirish')}</h2>
                            <form onSubmit={this.submitForm} className="contact_form_box" method="post" id="contactForm">
                                <div className="row">

                                    <div className="col-lg-12">
                                        <div className="form-group text_box">
                                            <textarea onClick={()=>this.speak2(t('xabarniKiriting'))}onChange={(event)=>this.setState({message:event.target.value})} name="message" id="message" cols="30" rows="10" placeholder={t('xabarniKiriting')}></textarea>
                                        </div>
                                        <div onClick={this.speak}className="form-group text_box">
                                            <label className="f_p text_c f_400">CAPTCHA</label>
                                            <ReCaptchaV2 sitekey={REACT_APP_SITE_KEY}
                                                         onChange ={(ews)=>this.verifyCallback(ews)}
                                                         onExpired={this.expiredCallback}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button onClick={(event)=>this.leaveMessage(event)}type="submit" className="btn_three">{t('xabarJonatish')}</button>
                            </form>
                            <img src={ct}/>
                            {/* {emailStatus ? emailStatus : null} */}
                            <div id="success">Your message succesfully sent!</div>
                            <div id="error">Opps! There is something wrong. Please try again</div>
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default ()=>{
    const {t} = useTranslation();
    return (
        <Contacts translation={{t}} />
    )
};


