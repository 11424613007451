import {API_URL} from "../Constants";
import i18next from "i18next";
import instance from "./api";

class SubscribeService {

    subscribe222(email)
    {
        return instance.get(`${API_URL}/api/subscribe/${email}`)
    }

}
export default new SubscribeService()
