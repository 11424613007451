
import {API_URL} from "../Constants";
import instance from "./api";

class DocFilesService {

    getDocFilesList(typ)
    {
        return instance.get(`${API_URL}/api/docfile/${typ}`,{

            withCredentials:true
        })
    }

    downloadFile(reportId)
    {
        return instance.post(`${API_URL}/api/filesdf/`,{
            withCredentials:true
        })
    }
    openFile(year,chorak,locale,typ)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=localStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
       return  instance.get(`${API_URL}/api/filesdf/open/?year=`+year+'&chorak='+chorak+'&locale='+locale+'&typ='+typ,{
            headers:{
                'Authorization':token
            },
           withCredentials:true,
           responseType: "blob",
        });
    }

    downloadFileF(year,chorak,locale,typ)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=localStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return  instance.get(`${API_URL}/api/filesdf/download/?year=`+year+'&chorak='+chorak+'&locale='+locale+'&typ='+typ,{
            headers:{
                'Authorization':token
            },
            withCredentials:true,
            responseType: "blob",

        });
    }

}



export default new DocFilesService()
