import React, {Component} from 'react';
import {Geo2} from "./Geo2";
import {useTranslation} from "react-i18next";
import {withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next';
import {Map, Placemark, YMaps,ZoomControl,
    FullscreenControl,
    GeolocationControl,
    ListBox,
    ListBoxItem,
    RouteButton,
    RouteEditor,
    RoutePanel,
    RulerControl,
    SearchControl,
    TrafficControl,TypeSelector} from "react-yandex-maps";
import BranchService from "../api/BranchService";
import i18next from "i18next";
import {API_URL} from "../Constants";
const mapData = {
    center: [40.078187, 65.407124],
    zoom: 15,
};

const coordinates = [
    [40.078187, 65.407124]

];

class Branches extends Component {
    constructor() {

        super()
        this.state = {
            name: '',
            email: '',
            subject: '',
            message: '',
            branch:'',
            id:''

        }
        this.handleChange = this.handleChange.bind(this)

    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    speak2=(x)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =x;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }

    componentDidMount() {
        const { match, location, history } = this.props

        BranchService.getById(location.id).then((response)=>
        this.setState({
            branch:response.data
        }))
        this._speech = new SpeechSynthesisUtterance();
    }

    render(){

        const { match, location, history } = this.props
        return(


            <section className="contact_info_area sec_pad bg_color">




                                <div style={{marginBottom:"60px"}}>
                                    <YMaps  >
                                        <Map defaultState={{center:[this.state.branch.coordinate_x,this.state.branch.coordinate_y],zoom:15}} height={400} width={1519}  >
                                            <ZoomControl/>
                                            <RouteButton/>
                                            <RouteEditor/>
                                            <RoutePanel/>
                                            <FullscreenControl/>
                                            <GeolocationControl/>
                                            <ListBox/>
                                            <ListBoxItem/>
                                            <RulerControl/>
                                            <SearchControl/>
                                            <TrafficControl/>
                                            <TypeSelector />
                                            <Placemark geometry={[this.state.branch.coordinate_x,this.state.branch.coordinate_y]} />

                                        </Map>
                                    </YMaps>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-3">

                                            <div className="contact_info_item">
                                                <h6 onClick={this.speak} className="f_p f_size_20 t_color3 f_500 mb_20">{this.props.t('addressHeader')}</h6>
                                                <p onClick={this.speak}className="f_400 f_size_15">{i18next.language==='uz'&&this.state.branch.address_uz}</p>
                                                <p onClick={this.speak}className="f_400 f_size_15">{i18next.language==='ru'&&this.state.branch.address_ru}</p>
                                                <p onClick={this.speak}className="f_400 f_size_15">{i18next.language==='kirill'&&this.state.branch.address_kirill}</p>
                                            </div>

                                            <div className="contact_info_item">
                                                <h6 onClick={this.speak}className="f_p f_size_20 t_color3 f_500 mb_20">{this.props.t('contacts')}</h6>
                                                <p onClick={this.speak}className="f_400 f_size_15"><span className="f_400 t_color3">Faks:</span>{this.state.branch.fax}</p>
                                                <p onClick={this.speak}className="f_400 f_size_15"><span className="f_400 t_color3">Email:</span>{this.state.branch.email}</p>
                                            </div>
                                        </div>
                                        <div className="contact_form col-lg-9">
                                            <h2 onClick={this.speak}className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">{this.props.t('xabarQoldirish')}</h2>
                                            <form onSubmit={this.submitForm} className="contact_form_box" method="post" id="contactForm">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="form-group text_box">
                                                            <input onClick={()=>this.speak2(this.props.t('ism'))} type="text" id="name" name="name" placeholder={this.props.t('ism')} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="form-group text_box">
                                                            <input onClick={()=>this.speak2('Email')}type="text" name="email" id="email" placeholder="Email" onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group text_box">
                                                            <input onClick={()=>this.speak2(this.props.t('mavzu'))}type="text" id="subject" name="subject" placeholder={this.props.t('mavzu')} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group text_box">
                                                            <textarea onClick={()=>this.speak2(this.props.t('xabarniKiriting'))}onChange={this.handleChange} name="message" id="message" cols="30" rows="10" placeholder={this.props.t('xabarniKiriting')}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button onClick={this.speak}type="submit" className="btn_three">{this.props.t('xabarJonatish')}</button>
                                            </form>
                                            {/* {emailStatus ? emailStatus : null} */}
                                            <div id="success">Your message succesfully sent!</div>
                                            <div id="error">Opps! There is something wrong. Please try again</div>
                                        </div>
                                    </div>

                                </div>

            </section>
        )
    }
}

export default withTranslation()(withRouter(Branches))
