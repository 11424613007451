"use strict"
import React, {useEffect, useRef, useState} from 'react';
import Blogrightsidebar from './Blogrightsidebar';
import ServiceData from '../Service/ServiceData';
import NewsService from "../../api/NewsService";
import {useLocation} from "react-router";
import renderHTML from 'react-render-html';
import DOMPurify from "dompurify";
import {API_URL} from "../../Constants";
import AuthenticationService from "../../api/AuthenticationService";
import CommentService from "../../api/CommentService";
import {toast} from "react-toastify";
import 'semantic-ui-css/semantic.min.css'
import { Button, Comment, Form, Header } from 'semantic-ui-react'
import moment from 'moment'
import {useTranslation} from "react-i18next";


import i18next from "i18next";
const Bloglists =(props)=>{
    const[language,setlanguage]=useState(i18next.language)
    const { t, i18n } = useTranslation();

const location=useLocation()
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView()

    const[loggedin,setloggedin]=useState(false)
    const queryString = require('query-string');
    const parsed = queryString.parse(window.location.search);
    const[typeOfComment,settypeOfComment]=useState('Komment qoldirish')
    const [title_uz,settitle_uz]=useState('');
    const [description_uz,setdescription_uz]=useState('');
    const[hascomment,sethascomment]=useState(false)
    const [title_ru,settitle_ru]=useState('');
    const [description_ru,setdescription_ru]=useState('');
    const [content_ru,setcontent_ru]=useState('');
    const [content_uz,setcontent_uz]=useState('')
    const [state, setState] = useState({});
    const[image_id,setimageid]=useState('')
    const[answer,setanswer]=useState('')
    const[day,setday]=useState('');
    const[replyTO,setreplyTO]=useState('')
    const[month,setmonth]=useState('');
    const[comment,setcomment]=useState('')
    const[ok,setok]=useState(false)
    const[replyClicked,setReplyClicked]=useState(false)
    const[parentComment,setparentComment]=useState('')
    const[realParentComment,setRealParentComment]=useState('')
    let news=useRef([])
    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }

    const _speech = new SpeechSynthesisUtterance();
    useEffect(()=>{
        let config={
            headers: {
                'language': {language},
            }
        }

        NewsService.getNewsById(parsed.id,config).then((response)=>{
            news.current=response.data
            setcontent_uz(response.data.content_uz)
            setimageid(response.data.imageid)
            settitle_uz(response.data.title_uz)
            setdescription_uz(response.data.description_uz)
            const date=new Date(news.current.createdAt)
            setday(date.getDate().toString())
            const monthNames = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            setmonth(monthNames[date.getMonth()])
            if(response.data.comments.length!==0)
                sethascomment(true)


        })
        setloggedin(AuthenticationService.isUserLoggedIn())
            return () => {
                setState({}); // This worked for me
            };
      }, []
    )
    let sendingComment={}
    const MakeComment=(event)=>{
        event.preventDefault()
        if(replyClicked)
        {
            sendingComment.comment=comment

            sendingComment.idOfNews=news.current.id
            sendingComment.parentComment=parentComment
            sendingComment.realParentComment=realParentComment
            CommentService.makeComment(sendingComment).then((response)=>{
            toast.success("comment has been added")
            setTimeout(function(){window.location.reload()}, 500);
        }).
        catch((response)=>{
            toast.error("Something went wrong")
        })}
        if(!replyClicked)
        {
            let sendingcomment2={
                'comment':comment,
                'idOfNews':news.current.id,
                'parentComment':'',
                'realParentComment':''
            }
            CommentService.makeComment(sendingcomment2).then((response)=>{
                toast.success("comment has been added")
                setTimeout(function(){window.location.reload()}, 500);
            }).
            catch((response)=>{
                toast.error("Something went wrong")
            })
        }
    }
    const CustomReply=(commentx)=>{

        setReplyClicked(true)
        settypeOfComment('Javob berish')
        setparentComment(commentx.id)
        setRealParentComment(commentx.realParentComment)
        executeScroll()


    }
    const replay=(comment)=>{
        let arr = `${comment.createdBy} yozgan: \n ${comment.comment} \n \n`
        setreplyTO(comment.id)
        setanswer(arr)
    }
    return(

        <section className="blog_area sec_pad">

            <div className="container">
                <div className="row">
                    <div className="col-lg-8 blog_sidebar_left">
                        <div className="blog_single mb_50">
                            <img className="img-fluid" src={`${API_URL}/api/files/${image_id}`} alt=""/>
                            <div className="blog_content">
                                <div className="post_date">
                                    <h2 onClick={speak}>{day}<span>{month}</span></h2>
                                </div>

                                {language=='uz'?<h1 onClick={speak} className="f_400 mb-30 bold">{title_uz}</h1>:<h1 className="f_400 mb-30 bold">{title_ru}</h1>}

                                {language=='uz'? <article onClick={speak}className="f_400 mb-30 bold" style={{color:'black'}}
                                    dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(content_uz)
                                    }}
                                />: <article onClick={speak} className="f_400 mb-30 bold" style={{color:'black'}}
                                             dangerouslySetInnerHTML={{
                                                 __html: DOMPurify.sanitize(news.current.content_ru)
                                             }}
                                />}
                                <div className="post_share">
                                    <div onClick={speak}className="post-nam"> {t('ulashish')} </div>
                                    <div className="flex">
                                        <a href=".#"><i className="ti-facebook"></i>Facebook</a>
                                        <a href=".#"><i className="ti-twitter"></i>Twitter</a>
                                        <a href=".#"><i className="ti-pinterest"></i>Pinterest</a>
                                    </div>
                                </div>
                                <Comment.Group size='massive' >
                                    <Header onClick={speak}size='large' as='h3' dividing>
                                        {t('kommentariyalar')}
                                    </Header>

                                    { hascomment && news.current.comments.map((comment,index)=> {
                                    if(comment.parentComment===null)
                                    return (
                                        <Comment key={index}>
                                            <Comment.Avatar as='a' src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
                                            <Comment.Content>
                                                <Comment.Author as='a'>{comment.createdBy}</Comment.Author>
                                                <Comment.Metadata>
                                                    <span>{moment(comment.createdAt).format(' HH:MM DD.MM.YYYY')}</span>
                                                </Comment.Metadata>
                                                <Comment.Text>
                                                    <p onClick={speak}>{comment.comment}</p>
                                                </Comment.Text>
                                                <Comment.Actions>
                                                    <button className="btn" onClick={()=>CustomReply(comment)}>Reply</button>
                                                </Comment.Actions>
                                            </Comment.Content>
                                        <Comment.Group key={index} size='massive'>
                                            {news.current.comments.map((comment2,index2)=>{

                                                if(comment2.realParentComment===comment.id&&comment2.parentComment!==null)
                                                    return <Comment key={index2}>
                                                        <Comment.Avatar as='a' src='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
                                                        <Comment.Content>
                                                            <Comment.Author as='a'>{comment2.createdBy+'->'+comment2.parentComment.createdBy}</Comment.Author>
                                                            <Comment.Metadata>
                                                                <span>{moment(comment2.createdAt).format(' HH:MM DD.MM.YYYY')}</span>
                                                            </Comment.Metadata>
                                                            <Comment.Text>
                                                                <p onClick={speak}>{comment2.comment}</p>
                                                            </Comment.Text>
                                                            <Comment.Actions>
                                                                <button className="btn" onClick={()=>CustomReply(comment2)}>Reply</button>
                                                            </Comment.Actions>
                                                        </Comment.Content>
                                                    </Comment>
                                            })}
                                        </Comment.Group>
                                        </Comment>

                                    )
                                    }
                                    )
                                }
                                    {loggedin&&
                                        <div ref={myRef}>
                                    <Form size='massive' reply>
                                        <Form.TextArea value={comment} onChange={e=>setcomment(e.target.value)}/>
                                        <Button size='massive' content={typeOfComment} onClick={MakeComment} labelPosition='left' icon='edit' primary />
                                    </Form>
                                        </div>}
                                </Comment.Group>
                            </div>
                        </div>
                        {!loggedin&&
                        <div className="widget_title mt_100">
                            <h3 className="f_p f_size_20 t_color3">{t('kommentariyaQoldirishUTK')}</h3>
                            <div className="border_bottom"></div>
                        </div>
                        }
                    </div>
                    <div className="col-lg-4">
                        <Blogrightsidebar translation={{t}} ServiceData={ServiceData}/>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Bloglists;
