import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle11 = () => {

    const _speech = new SpeechSynthesisUtterance();

    const speak = (event) => {
        if (window.isSpeakingEnabled) {
            _speech.text = event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);
        }
    }
    return (
        <section className="portfolio_details_area sec_pad" style={{maxHeight: "3000px", minHeight: "1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft: "8%"}} className="col-lg-8 ">
                <img style={{height: 250, width: 200, float: 'right'}} className="img-fluid mb_20"
                     src={require('../../img/teams/karmanaBoshliq.jpg')} alt=""/>
                <div onClick={speak} className="row">
                    <div>
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Ramazonov Otabek Maxmudovich</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2024 yil 5
                                avgustdan:
                                Karmana tuman yo‘llardan foydalanish davlat muassasasi direktori


                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">MEHNAT FAOLIYATI</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                1993-1998 yy. - Toshkent avtomobil yo‘llari instituti talabasi</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>1998-1999 yy.
                                - Qiziltepa-Karvon 118-son avtobazada ta’mirlovchi
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}> 1999-2003
                                yy. - Qizilqum yo‘llarini ta’mirlash va foydalanish korxonasida yo‘l ustasi, harakat
                                xavfsizligi muhandisi
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}> 2003-2005
                                yy. - Qizilqum yo‘llarni ta’mirlash va foydalanish korxonasida iqtisodchi </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}> 2005-2007
                                yy. - Uchquduq tuman yo‘l xo‘jaligi pudrat ta’mirlash foydalanish korxonasida ishlab
                                chiqarish bo‘limi boshlig‘i</p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2007-2010 yy. - Uchquduq tuman yo‘l xo‘jaligi pudrat ta’mirlash foydalanish korxonasi
                                bosh muhandisi
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2010-2011 yy. - O‘zbekiston Respublikasi Prezidenti huzuridagi Davlat va jamiyat qurilishi akademiyasi tinglovchisi
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2011-2012 yy. - Uchquduq tuman yo‘l xo‘jaligi pudrat ta’mirlash foydalanish korxonasi bosh muhandisi
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2012-2017 yy. - Uchquduq tuman yo‘l xo‘jaligi pudrat ta’mirlash foydalanish korxonasi direktori
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2017-2018 yy. - “Navoiy mintaqaviy yo‘llarga buyurtmachi xizmati” davlat unitar korxonasi direktori
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2018-2020 yy. -O‘zbekiston Respublikasi Transprot vazirligi huzuridagi yo‘l qurilish ishlari sifatini nazorat qilish inspeksiyasi davlat inspektori
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2020-2024 yy.  - Uchquduq tumani hokimining qurilish, kommunikatsiyalar, kommunal xo‘jalik, ekologiya va  ko‘kalamzorlashtirish masalalari bo‘yicha o‘rinbosari
                            </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2024 y. h. v.  – Karmana tuman yo‘llardan foydalanish davlat  muassasasi direktori.
                            </p>


                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle11;
