import React, {Component} from 'react';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Row,
    Table,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Blogrightsidebar from "../../Blog/Blogrightsidebar";
import {withTranslation} from "react-i18next";
import Blogrightsidebar3 from "../../Blog/Blogrightsidebar3";
import Blogrightsidebar4 from "../../Blog/Blogrightsidebar4";
class FileServiceMuhimFaktlar extends Component{
    render(){
        return(
            <section className="agency_service_area bg_color" style={{marginBottom:"10px"}}>
                <div>
                <div style={{float:"left",width:"20%",marginRight:"5%"}}>
                    <Blogrightsidebar3 />
                </div>
                <div style={{float:"right",width:"70%",marginRight:"5%",marginTop:"70px"}} >


                </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(FileServiceMuhimFaktlar);
