import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
import PropTypes from 'prop-types';
import BlogGridItem from "../../components/Blog/BlogGridItem";
import {API_URL} from "../../Constants";
import NewsService from "../../api/NewsService"
import Breadcrumb from "../../components/Breadcrumb";
import Blogrightsidebar from "../../components/Blog/Blogrightsidebar";
import ServiceData from "../../components/Service/ServiceData";

import {useTranslation} from "react-i18next";
import Flags from 'country-flag-icons/react/3x2'
import i18next from "i18next";
import Blogrightsidebar2 from "../../components/Blog/Blogrightsidebar2";
window.React = React;



export class CommentList extends Component {
    static propTypes = {
        data: PropTypes.array.isRequired,
    };

    render() {
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        let commentNodes = this.props.data.map(function (pd, index) {
            const date=new Date(pd.createdAt)
            return  <BlogGridItem key={index} id={pd.id}  date={date.getDate()} month={monthNames[date.getMonth()]} image={`${API_URL}/api/files/${pd.imageid}`} Title={pd.title_uz} description={pd.description_uz}
                                                          btn="Ko'proq bilish" comment={pd.comments.count}/>
        });

        return (
        <React.Fragment>
             {commentNodes}
    </React.Fragment>

        );
    }
}

export class NewsList extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
        perPage: PropTypes.number.isRequired,

    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            offset: 0,

            selected:0,
        };
    }

    loadCommentsFromServer() {

        NewsService.getAllNews().then((response)=>{

            this.setState({
            data: response.data.slice(Math.ceil(this.state.selected*this.props.perPage),Math.ceil(this.props.perPage*Math.ceil(this.state.selected+1))),

            pageCount: Math.ceil(response.data.length / this.props.perPage),
        }
        );
        })
    }

    componentDidMount() {
        this.loadCommentsFromServer();
    }

    handlePageClick = (data) => {
        let selected = data.selected;

        let offset = Math.ceil(selected * this.props.perPage);

        this.setState({ offset: offset,
                            selected:selected
        }, () => {
            this.loadCommentsFromServer();
        });
    };

    render() {
        const{t}=this.props.translation
        return (
            <React.Fragment>
                <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle={t('news')} /*Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"*//>
                <section className="blog_area_two sec_pad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 blog_grid_info">
                                <div className="row">
                                    <CommentList data={this.state.data} />
                                </div>
                                <ReactPaginate
                                    previousLabel={'previous'}
                                    nextLabel={'next'}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={'pagination'}
                                    activeClassName={'active'}
                                />

                            </div>
                            <div className="col-lg-4">
                                <Blogrightsidebar2/>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>


        );
    }
}


