import React from 'react';
import Sectitle from '../Title/Sectitle';
import Teamitem from '../Team/Teamitem';
import {Link, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";
const TeamRegionList =()=>{
    const { t, i18n } = useTranslation();
    const _speech = new SpeechSynthesisUtterance();


    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }

    return(
        <section className="blog_area_two sec_pad" style={{maxHeight:"3000px",minHeight:"1000px", height:"2650px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%",}} className="col-lg-8 ">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle12">
                            <Teamitem teamImage="mintaqaBoshliq.jpg" memberN="Исмоилов Севдиёр Мухитдин ўғли" memberd={t('mintaqaBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle7">
                            <Teamitem teamImage="kuprikBoshliq.jpg" memberN="Ҳамроев Баҳром Шукурович" memberd={t('kuprikBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle8">
                            <Teamitem teamImage="muntazamDirektor.jpg" memberN="Рўзиев Рамазон Нуриллоевич" memberd={t('muntazamDirektor')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak} to="/portfoliosingle6">
                        <Teamitem teamImage="KonimexDirektor.jpg" memberN="Хошимов Озод Элмуродович" memberd={t('konimexBoshliq')}/>
                        </Link>
                    </div>


                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle9">
                            <Teamitem teamImage="xatirchiBoshliq.jpg" memberN="Фозилов Мунисжон Муродулло ўғли" memberd={t('xatirchiBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle10">
                            <Teamitem teamImage="kiziltepaBoshliq.jpg" memberN="Мажидов Жасур Қўлдошевич" memberd={t('kiziltepaBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle11">
                            <Teamitem teamImage="karmanaBoshliq.jpg" memberN="Ramazonov Otabek Maxmudovich" memberd={t('karmanaBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle13">
                            <Teamitem teamImage="tomdiBoshliq.jpg" memberN="Норов Илхомжон Шавқиддинович" memberd={t('tomdiBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle14">
                            <Teamitem teamImage="nurotaBoshliq.jpg" memberN="Norboboyev Ravshan Yodgorovich" memberd={t('nurotaBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle15">
                            <Teamitem teamImage="navbaxorBoshliq.jpg" memberN="Гулов Қодир Қаҳҳорович" memberd={t('navbaxorBoshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle16">
                            <Teamitem teamImage="uchquduqBoshliq.jpg" memberN="Zokirov Ashraf Hazratqulovich" memberd={t('uchquduqBoshliq')}/>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default TeamRegionList;
