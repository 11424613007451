import React, {useEffect} from 'react';
import Reveal from 'react-reveal/Reveal/';
import { Header }from '../headerCustom'
import JsonData from '../../data/data.json'
import i18next from "i18next";
import {useTranslation} from "react-i18next";
const DesignBanner = () => {
    const { t, i18n } = useTranslation();
    const isMobile = window.innerWidth <= 500;
    const isPC=window.innerWidth >= 780;


            return (<section className="grey" style={{ backgroundImage: `url(${require("../../img/seo/image.png")})` ,
                backgroundRepeat:"no-repeat",

                backgroundSize: "100% auto",
                backgroundPosition: "center top",
                // backgroundAttachment: "fixed",
                // position: "relative",

                height:"750px"
                // zIndex: "1"
            }}>
                {/*       <div className="home_bubble">
                <div className="bubble b_one"></div>
                <div className="bubble b_two"></div>
                <div className="bubble b_three"></div>
                <div className="bubble b_four"></div>
                <div className="bubble b_five"></div>
                <div className="bubble b_six"></div>
                <div className="triangle b_seven" data-parallax='{"x": 20, "y": 150}'><img src={require('../../img/seo/triangle_one.png')} alt=""/></div>
                <div className="triangle b_eight" data-parallax='{"x": 120, "y": -10}'><img src={require('../../img/seo/triangle_two.png')} alt=""/></div>
                <div className="triangle b_nine"><img src={require('../../img/seo/triangle_three.png')} alt=""/></div>
            </div>*/}
                <div  id="grey" className="banner_top"style={{paddingTop:"1px",marginTop:"1px"}}>
                    <div className="container"  >
                        <div className="row">
                            {/*<div className="col-md-12 text-center seo_banner_content"  >*/}
                            {/*    <Reveal effect="fadeInUp" duration={500}><h3 style={{color:"#6675d4"}}> NAVOIY VILOYATI AVTOMOBIL   <br/>YO'LLARI BOSH BOSHQARMASI</h3></Reveal>*/}
                            {/*    /!*<Reveal effect="fadeInUp" duration={1000}><p className="wow fadeInUp" data-wow-delay="0.5s" style={{color:"#ffffff",marginTop:"60px",fontSize:"25px"}}>Chap fantastic skive off chancer knees up starkers easy peasy up the<br/> kyver David, bleeding the BBC tomfoolery chimney.!</p></Reveal>*!/*/}
                            {/*    /!* <Reveal effect="fadeInLeft" duration={1200}><a href="#Service" className="seo_btn seo_btn_one btn_hover wow fadeInLeft">Boshlash</a></Reveal>*!/*/}
                            {/*    /!*<Reveal effect="fadeInLeft" duration={1200}><a href="./" className="seo_btn seo_btn_two btn_hover wow fadeInRight">Learn More</a></Reveal>*!/*/}
                            {/*</div>*/}
                        </div>
                        <div className="saas_home_img">
                            {/*  <Reveal effect="fadeInUp" duration={1400}><img src={require('../../img/seo/image.png')} alt=""/></Reveal>*/}
                        </div>
                    </div>
                </div>
            </section>)

}
export default DesignBanner;
