import React, {Component} from 'react';

import {useTranslation} from "react-i18next";
import Blogrightsidebar3 from "./Blog/Blogrightsidebar3";
import {Container,Row,Col,Card,CardHeader,CardBody,CardFooter,Form,FormGroup,Label,Input,Button} from 'reactstrap'
import { DefaultFormLayout,EmailAddress,Email,Password,Username,Website,BillingInformation,CompanyInformation,InlineForm,InlineFormWithLabel,InlineFormWithoutLabel,HorizontalFormLayout,MegaForm,Submit,Cancel,AccountInformation,Option,Login,ContactNumber,CompanyName,YourName,Checkboxes,Radios,Disabled } from "../../src/constant/index";
import AuthenticationService from "../api/AuthenticationService";
import Popup from 'reactjs-popup';
import {toast,ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MessageService from "../api/MessageService";
import history from "../index";

class UserSettings extends Component {

    constructor() {
        super()
        this.state = {
            name: '',

            subject: '',
            message: '',
            username:'',
            user:'',
            answeredmessages:[],
            allmessages:[]
        }
        this.handleChange = this.handleChange.bind(this)

    }

    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }


    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    }
    showSuccessToast = () => {
            const{t}=this.props.translation

            return toast.success(t('successUpdate'));

    };
    showErrorToast = () => {
        const{t}=this.props.translation

        return toast.error(t('error'));

    };
    componentDidMount() {

        AuthenticationService.getUserDetails().then(
            (response)=>{
                this.setState({
                    user:response.data
                })
            }
        )
        this._speech = new SpeechSynthesisUtterance();

        MessageService.getall().then((res)=>{
            this.setState({
                allmessages:res.data
            })
        })
        MessageService.getanswered().then((res)=>{
            this.setState({
                answeredmessages:res.data
            })
        })
    }

   updateUser=(event)=>{
        event.preventDefault();
        AuthenticationService.updateUser(this.state.user).then(
            (response)=>{

                this.showSuccessToast()
                setTimeout(function(){window.location.reload()}, 2000);
            }
        ).catch(
            function (error) {
                this.showErrorToast()
            }
        )

    }
     setfirstName=(event)=>{
        this.state.user.firstName=event.target.value
    };
     setlastName=(event)=>{
        this.state.user.lastName=event.target.value
    };
     setuserName=(event)=>{
        this.state.user.userName=event.target.value
    };
     setPassword=(event)=>{
        this.state.user.password=event.target.value
    };


     settel=(event)=>{
        this.state.user.tel=event.target.value
    }

    gotoallmessages=()=>{

        history.push({
            pathname: '/user/messages/all',
            state: this.state.allmessages
        })
    }
    gotoansweredmessages=()=>{
        history.push({
            pathname: '/user/messages/answered',
            state: this.state.answeredmessages
        })
    }

    render(){
        const{t}=this.props.translation

        return(
            <section className="h_pricing_area sec_pad">
                <ToastContainer/>
                    <div className="row">
                        <div className="col-lg-3">
                          <Blogrightsidebar3/>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <h2 className="f_p f_size_22 t_color3 f_600 l_height28 mb_40">{t('userSettings')}</h2>
                            <Card >
                                <CardHeader>
                                    <FormGroup>

                                        <Label onClick={this.speak}  className="col-form-label">{t('leftMessages')}{this.state.allmessages.length}</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <button className="btn btn-primary" type="submit" onClick={this.gotoallmessages} >{t('korish')}</button>

                                    </FormGroup>
                                    <FormGroup>

                                        <Label onClick={this.speak}className="col-form-label">{t('answeredMessages')}{this.state.answeredmessages.length}</Label>
                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <button className="btn btn-primary" type="submit" onClick={this.gotoansweredmessages}>{t('korish')}</button>
                                    </FormGroup>
                                    <FormGroup>

                                        <Label onClick={this.speak}className="col-form-label">{t('xabarWherePost')}</Label>


                                    </FormGroup>
                                </CardHeader>
                                <CardBody>
                                    <Form className="theme-form mega-form" style={{fontSize:"20px"}}>
                                        <h6 onClick={this.speak}>{t('userSettings')}</h6>
                                        <FormGroup >
                                            <Label onClick={this.speak}className="col-form-label">{t('username')}</Label>
                                            <Input style={{fontSize:"100%",height:"100%"}} className="form-control" type="text" defaultValue={this.state.user.username} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label onClick={this.speak}className="col-form-label">{t('ism')}</Label>
                                            <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="text" defaultValue={this.state.user.firstName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label onClick={this.speak}className="col-form-label">{t('familiya')}</Label>
                                            <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="text" defaultValue={this.state.user.lastName} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label onClick={this.speak} className="col-form-label">{t('eMailM')}</Label>
                                            <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="email" defaultValue={this.state.user.email} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label onClick={this.speak}className="col-form-label">{t('tel')}</Label>
                                            <Input style={{fontSize:"100%",height:"100%"}}className="form-control" type="Number" defaultValue={this.state.user.tel} />
                                        </FormGroup>
                                        <hr className="mt-4 mb-4" />
                                   </Form>
                                </CardBody>
                                <CardFooter>
                                    <Popup  trigger={ <Button style={{fontSize:"100%",height:"100%"}} color="primary" className="mr-1">{t('ozgartirish')}</Button>} position="right center" modal nested>
                                        <Container fluid={true}>
                                            <Card style={{
                                                maxHeight: 'calc(100vh - 210px)',
                                                overflowY: 'auto',
                                                width:"600px"

                                            }}>
                                                <Col xl="16">
                                                    <Form className="card">
                                            <CardHeader>
                                                <h4 className="card-title mb-0">EditProfile</h4>
                                                <div className="card-options">
                                                    <a className="card-options-collapse" href="#javascript">
                                                        <i className="fe fe-chevron-up"></i>
                                                    </a>
                                                    <a className="card-options-remove" href="#javascript">
                                                        <i className="fe fe-x"></i>
                                                    </a>
                                                </div>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col >

                                                        <FormGroup>
                                                            <Label onClick={this.speak} className="form-label">{t('ism')}</Label>
                                                            <Input className="form-control" type="text" defaultValue={this.state.user.firstName} onChange={this.setfirstName} />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label onClick={this.speak}className="form-label">{t('familiya')}</Label>
                                                            <Input className="form-control" type="email" defaultValue={this.state.user.lastName} onChange={this.setlastName} />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label onClick={this.speak}className="form-label">{t('password')}</Label>
                                                            <Input className="form-control" type="text" placeholder="New Password" onChange={this.setPassword} />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label onClick={this.speak}className="form-label">{t('tel')}</Label>
                                                            <Input className="form-control" type="text" defaultValue={this.state.user.tel} onChange={this.settel} />
                                                        </FormGroup>
                                                        {/*<FormGroup>*/}
                                                        {/*  <Label className="form-label">Roles</Label>*/}
                                                        {/*  <Input className="form-control" type="number" placeholder={roles} />*/}
                                                        {/*</FormGroup>*/}

                                                        {/*<div className="form-group mb-0">*/}
                                                        {/*  <Label className="form-label">About</Label>*/}
                                                        {/*  <Input type="textarea" className="form-control" rows="5" placeholder="Enter About your description" />*/}
                                                        {/*</div>*/}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                                        <CardFooter className="text-right">
                                                            <button className="btn btn-primary" type="submit" onClick={this.updateUser}>{t('yangilash')}</button>
                                                        </CardFooter>
                                                    </Form>
                                                </Col>
                                            </Card>
                                        </Container>

                                    </Popup>



                                </CardFooter>
                            </Card>


</div>


                    </div>


            </section>
        )
    }
}

export default ()=>{
    const {t} = useTranslation();
    return (
        <UserSettings translation={{t}} />
    )
};
