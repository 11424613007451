import React from 'react';
import Sectitle from '../Title/Sectitle';
import Teamitem from '../Team/Teamitem';
import {Link, NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";
const Team =()=>{
    const { t, i18n } = useTranslation();
    const _speech = new SpeechSynthesisUtterance();


    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }

    return(
        <section className="blog_area_two sec_pad" style={{maxHeight:"3000px",minHeight:"1300px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%"}} className="col-lg-8 ">
                <div className="row">
                    {/*<div className="col-lg-4 col-sm-6">*/}
                    {/*    <Link onClick={speak} to="/portfoliosingle">*/}
                    {/*        <Teamitem  teamImage="boshliq.jpg" memberN="Камолов Эркин Шамсиддинович" memberd={t('boshliq')}/>*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak} to="/portfoliosingle3">
                        <Teamitem teamImage="urinbosar2.jpg" memberN="Ҳалимов Алишер Ҳамзаевич" memberd={t('boshliq')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle20">
                            <Teamitem teamImage="urinbosar4.jpg" memberN="Рахмонов Бойпўлот Чинпўлотович" memberd={t('newUrinbosar')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle2">
                            <Teamitem teamImage="muhandis.jpg" memberN="Рохматов Улуғбек Ғуломович" memberd={t('boshMuhandis')}/>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-sm-6">
                        <Link onClick={speak}to="/portfoliosingle21">
                            <Teamitem teamImage="kotib.jpg" memberN="Баҳромов Дилшоджон Улуғбек ўғли" memberd={t('matbuotKotibi')}/>
                        </Link>
                    </div>

                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_04.jpg" memberN="Bailey Wonger" memberd="Marketer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_5.jpg" memberN="Giles Posture" memberd="App designer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_6.jpg" memberN="Rodney Artichoke" memberd="UI/UX designer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_7.jpg" memberN="Jackson Pot" memberd="Marketer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_8.jpg" memberN="Bailey Wonger" memberd="Marketer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_9.jpg" memberN="Max Conversion" memberd="Social Marketer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_10.jpg" memberN="Jake Weary" memberd="UI/UX designer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_9.jpg" memberN="Justin Case" memberd="Marketer"/>*/}
                    {/*</div>*/}
                    {/*<div className="col-lg-3 col-sm-6">*/}
                    {/*    <Teamitem teamImage="team_12.jpg" memberN="Norman Gordon" memberd="Web developer"/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>

    )
}
export default Team;
