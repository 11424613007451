import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import DocFilesSearchService from "../../api/DocFilesSearchService";
import history from "../../index";
import Pagination from "rc-pagination";
import {Table} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import AuthenticationService from "../../api/AuthenticationService";
import {API_URL} from "../../Constants";
import instance from "../../api/api";
import DocFilesService from "../../api/DocFilesService";

class SequrityPrice extends Component {
    constructor(){
        super();
        this.state= {
            current:1,
            size:7,
            docfiles:[],
            total:0,
            hasResults:false

        }
    }
    onChange=(x)=>{
        DocFilesSearchService.search(history.location.state,x-1,this.state.size).then((res)=>{
            this.setState({
                docfiles:res.data,
                current:x,
                total:res.headers['total'],

            })
            if(res.data.length!==0)
                this.setState({
                    hasResults:true
                })
            window.scrollTo({top:0,behavior:"smooth"})
        })
    }
    componentDidMount() {
        window.scrollTo({top:0,behavior:"smooth"})
        DocFilesSearchService.search(history.location.state,this.state.current-1,this.state.size).then((res)=>{
            this.setState({
                docfiles:res.data,
                total:res.headers['total'],
            })
            if(res.data.length!==0)
                this.setState({
                    hasResults:true
                })
        })
    }
    viewPDF=(file)=>{

       DocFilesService.openFile(file.id.year,file.id.chorak,file.id.locale,file.id.typ).then((response)=>{
           const file = new Blob([response.data], { type: "application/pdf" });
           //Build a URL from the file
           const fileURL = URL.createObjectURL(file);
           //Open the URL on new Window
           const pdfWindow = window.open();
           pdfWindow.location.href = fileURL;
       })


    }

    download=(file)=>{

        DocFilesService.downloadFileF(file.id.year,file.id.chorak,file.id.locale,file.id.typ)

            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    blob.headers['filename'],
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }
    render(){
        return(
            <section className="pricing_area_four sec_pad">
            <div className="container">
                <div className="hosting_title security_title text-center">
                    <h2 className="wow fadeInUp" data-wow-delay="0.2s"><span>{this.props.t('qidiruvN')}</span></h2>
                </div>
                {this.state.hasResults&&<div className="table-responsive bg-white shadow rounded mt-4">
                    <Table className="mb-0 table-center">
                        <thead className="bg-light">
                        <tr>
                            <th scope="col" style={{ minWidth: "300px" }}>
                                {this.props.t('dokumentN')}
                            </th>
                            <th
                                scope="col"
                                className="text-center"
                                style={{ maxWidth: "150px" }}
                            >
                                {this.props.t('hajmi')}
                            </th>
                            <th
                                scope="col"
                                className="text-center"
                                style={{ width: "100px" }}
                            >

                            </th>
                            <th
                                scope="col"
                                className="text-center"
                                style={{ width: "100px" }}
                            >

                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.docfiles.map((f,k)=><tr key={k}>
                            <td>
                                <div className="media">
                                    <FeatherIcon icon="file" className="fea icon-ex-md"/>
                                    <div className="content ml-3" style={{fontSize: "14px"}}>
                                        <Link
                                            to="forums-topic"
                                            className="forum-title text-primary font-weight-bold"
                                        >
                                            {f.name}
                                        </Link>
                                        <p className="text-muted small mb-0 mt-2">

                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td className="text-center small h6">{f.docFileLocation.size}</td>
                            <td className="text-center small">
                                <button className="btn small btn-primary" onClick={()=>this.viewPDF(f)}
                                        style={{fontSize: "10px"}}> {this.props.t('korish')}</button>
                            </td>
                            <td className="text-center small">
                                <button className="btn small btn-primary"
                                        style={{fontSize: "10px"}} onClick={()=>this.download(f)}>{this.props.t('yuklash')}</button>
                            </td>
                        </tr>)}</tbody>
                    </Table>
                    <Pagination
                        onChange={(x)=>this.onChange(x)}
                        current={this.state.current}
                        total={this.state.total}
                        pageSize={this.state.size}
                    />
                </div>}
                {!this.state.hasResults&&<div className="price_body">
                    <div className="pr_list">
                        <div className="price_item">
                            <h2 className="results" data-placement="top" title="">{this.props.t('hechNT')}</h2>
                        </div>
                    </div>
                </div>}
            </div>
        </section>
        )
    }
}
export default withTranslation()(SequrityPrice);
