import React, {Component} from 'react';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
import Blogrightsidebar from "../../Blog/Blogrightsidebar";
class Sservice extends Component{
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }

    render(){
        return(
            <section className="agency_service_area bg_color" style={{marginBottom:"10px"}}>
                <div id="gray">
                <div style={{float:"left",width:"20%",marginRight:"5%"}}>
                    <Blogrightsidebar />
                </div>
                <div style={{float:"right",width:"70%",marginRight:"5%"}} >

                    <div onClick={this.speak} >

                        <img style={{float:"left",border:"7%",maxHeight:"350px" ,maxWidth:"650px",margin:"0 20px 0 0"}}  src={require('../../../img/svg222.svg')}  alt=""/>
                        <p  style={{color:'black',fontSize:'20px'}}>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}><span>&nbsp;&nbsp;&nbsp;</span> 1. Ўзбекистон Республикаси Президентининг 2017 йил 14 февралдаги “Йўл хўжалигини бошқариш тизимини янада такомиллаштириш чора-тадбирлари тўғрисида”ги ПФ -4954-сон Фармонига асосан тугатилаётган Автомобиль йўллари қурилиш ва фойдаланиш давлат-акциядорлик компанияси (‘‘Ўзавтойул’ ДАК) негизида Ўзбекистон Республикаси Автомобиль йўллари давлат қўмитаси ташкил этилган. Ўзбекистон Республикаси Президентининг 2017 йил 14 февралдаги "Ўзбекистон Республикаси Автомобиль йўллари давлат қўмитаси ва Ўзбекистон Республикаси Вазирлар Махкамаси ҳузуридаги Республика йўл жамгармаси фаолиятини ташкил этиш тўғрисида”ги ПК-2776-сон қарорига мувофик Навоий вилояти автомобиль йўллари ҳудудий бош бошқармаси Ўзбекистон Республикаси Автомобиль йўллари давлат кўмитаси ташкилий тузилмасига киритилган бўлиб, Ўзбекистон Республикаси Автомобиль Йўллари давлат қўмитасининг  2017 йил 1- мартдаги 06-сонли буйруғига асосан ташкил этилган.
                            “Навоий вилояти автомобиль йўллари ҳудудий бош бошқармаси (кейинги ўринларда – “Бош бошқарма”) Ўзбекистон Республикаси Президентининг 2019 йил 9 декабрдаги “Йўл соҳасини бошқариш тизимини янада такомиллаштиришга оид чора-тадбирлари тўғрисидаги” ПҚ-4545 сонли карорига асосан Ўзбекистон Республикаси Транспорт вазирлиги ҳузуридаги Автомобиль йўллари қўмитаси (кейинги ўринларда – “Муассис”)нинг ташкилий таркибига киритилган.
                                <span>{<br/>}</span> </p><span>{<br/>}</span>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  2. Бош бошқарма ўз фаолиятини Ўзбекистон Республикаси Конституцияси ва қонунларига, Ўзбекистон Республикаси Олий Мажлиси палаталарининг қарорларига, Ўзбекистон Республикаси Президентининг фармонлари, қарорлари ва фармойишларига, Ўзбекистон Республикаси Вазирлар Маҳкамасининг қарорлари ва фармойишларига, Муассиснинг қарорлари, фармойишлари ва буйруқларига, мазкур Низомга ҳамда соҳага оид бошқа қонун ҳужжатларига мувофиқ амалга оширади.
                            Бош бошқарма ўз фаолиятини тўлиқ хўжалик ҳисоби асосида амалга оширади ва бевосита Муассисга буйсунади.
                                <span>{<br/>}</span> </p><span>{<br/>}</span>
                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  3. Транспорт вазирлиги ҳузуридаги Автомобиль йўллари қўмитаси Бош бошқарманинг Муассиси ҳисобланади.
                                    <span>{<br/>}</span>  </p><span>{<br/>}</span>
                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  4. Бош бошқарма ўз вазифалари ва функцияларини амалга оширишда давлат ва хўжалик бошқаруви органлари, маҳаллий ижро этувчи ҳокимият органлари ва бошқа ташкилотлар билан ўзаро ҳамкорлик қилади.
                                        <span>{<br/>}</span>  </p><span>{<br/>}</span>
                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  5. Бош бошқарма Навоий вилоятидаги автомобиль йўлларини давлат томонидан бошқарадиган махсус ваколатли идора бўлиб ҳисобланади ва вилоят ҳудудидаги автомобиль йўллари соҳасида Муассиснинг ягона техника сиёсатини олиб боради.
                                            <span>{<br/>}</span>  </p><span>{<br/>}</span>
                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>   6. Бош бошқарма таркибига вилоят ҳудудида жойлашган туман йўллардан фойдаланиш унитар корхоналари, кўприклардан фойдаланиш унитар корхоналар ҳамда Муассис томонидан ўрнатилган тартибда янги ташкил этиладиган корхона ва ташкилотлар киради.
                            Бош бошқарма таркибига кирувчи корхона ва ташкилотлар ўзларининг хўжалик мустақиллигини ва юридик шахс мақомини сақлаб қоладилар.
                                                <span>{<br/>}</span>   </p><span>{<br/>}</span>
                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',}}> 7. Бош бошқармани сақлаш ҳаражатлари Ўзбекистон Республикаси Молия Вазирлиги ҳамда Жамғарма маблағлари ҳисобидан амалга оширилади.
                                                    <span>{<br/>}</span> </p><span>{<br/>}</span>
                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  8. Бош бошқарма давлат рўйхатидан ўтказилган кундан бошлаб юридик шахс мақомига эга бўлади ва унинг фаолият муддати чекланмаган.
                                                        <span>{<br/>}</span>  </p><span>{<br/>}</span>
                                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  9. Бош бошқарма Низомига ўзгартиришлар Муассиснинг қарорига асосан киритилади ва белгиланган тартибда давлат рўйхатидан ўтказилади.
                                                            <span>{<br/>}</span>   </p><span>{<br/>}</span>
                                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>   10. Бош бошқарма юридик шахс ҳисобланади, думалоқ муҳрга ва Ўзбекистон Республикаси Давлат герби тасвири туширилган ўз номи давлат тилида ёзилган бланкаларга, алоҳида мол-мулкка, мустақил балансга, Ўзбекистон Республикаси Молия вазирлигининг Ғазначилигида ҳамда банкларда ҳисоб рақамларига, шунингдек хорижий валюта ҳисоб рақамига эга бўлади.
                                                                <span>{<br/>}</span>   </p><span>{<br/>}</span>
                                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',}}>  11. Бош бошқарма қонун ҳужжатларида белгиланган тартибда ўз номидан мулкий ва шахсий номулкий ҳуқуқларни олиши ва амалга ошириши, мажбуриятлар олиши, судда даъвогар ва жавобгар бўлиши мумкин.
                                                                    <span>{<br/>}</span>     </p><span>{<br/>}</span>
                                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  12. Бош бошқарма бошқа корхона ва ташкилотларнинг Муассиси бўлишга ҳақли эмас.
                                                                        <span>{<br/>}</span>   </p><span>{<br/>}</span>
                                                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>  13. Бош бошқарманинг юридик манзили: Ўзбекистон Республикаси, Навоий вилояти, Навоий шаҳри, Гулистон қўрғони, Наврўз кўчаси, 29- уй.
                                                                            <span>{<br/>}</span>    </p><span>{<br/>}</span>
                                                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>   14. Бош бошқарманинг асосий вазифалари:
                            вилоят ҳудудида автомобиль йўллари соҳасида Муассиснинг ягона техник сиёсатини олиб бориш;
                            вилоят автомобиль йўлларини ривожлантириш давлат дастурлари лойиҳаларини ишлаб чиқиш ва Муассисга киритиш ҳамда тасдиқланган давлат дастурларини амалга ошириш;
                            вилоят автомобиль йўллари тармоқларини ривожлантириш ва такомиллаштириш истиқболларини белгилаш юзасидан Муассисга таклифлар киритиш;
                            вилоят ҳудудидан ўтадиган автомобиль йўлларининг халқаро транзит йўлакларини шакллантириш ва ривожлантириш бўйича таклифлар ишлаб чиқиш ва Муассисга киритиш;
                            замонавий транспорт оқими шароитларида автомобиль йўлларидан фойдаланувчиларнинг манфаатларини ҳисобга олиб, автомобиль йўлларидан фойдаланиш масалаларини комплекс ҳал этилишини таъминлаш;
                            умумий фойдаланишдаги автомобиль йўлларини сақлаш, жорий таъмирлаш, табиий офат оқибатларини олдини олиш ва бартараф этиш ҳамда жиҳозлаш (техника, машина-механизмлар, технологик ускуналарни харид қилиш) ишлари бўйича буюртмачилик вазифасини амалга ошириш ҳамда сифати устидан назоратни олиб бориш;
                            хўжаликлараро қишлоқ автомобиль йўлларининг, шаҳарлар, шаҳар посёлкалари, қишлоқлар ва овуллар кўчаларини лойиҳалаштириш, қуриш, реконструкция қилиш ва таъмирлаш ишларини мувофиқлаштириш;
                            илмий-тадқиқот ишларини ташкил этиш, автомобиль йўлларини лойиҳалаштириш, қуриш, реконструкция қилиш, таъмирлаш ва сақлаш соҳасида инновацион технологиялар ва замонавий стандартларни жорий этиш;
                            автомобиль йўллари соҳасида кадрларни тайёрлаш, қайта тайёрлаш ва уларнинг малакасини оширишни, ўқув-амалиёт курслари ва семинарлар ташкил этиш.
                                                                                <span>{<br/>}</span>      </p><span>{<br/>}</span>
                                                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px'}}>    15. Бош бошқарма ўзига юкланган вазифаларга мувофиқ қуйидаги функцияларни амалга оширади:
                            умумий фойдаланишдаги автомобиль йўлларини жорий таъмирлаш ва сақлаш борасида техник жиҳатдан тартибга солиш соҳасидаги норматив ҳужжатлар талабларига риоя этилишини таъминлаш;
                            умумий фойдаланишдаги автомобиль йўллари таъмирлаш ва сақлашда участкаларда ҳаракат хавфсизлигини таъминлашга йўналтирилган тадбирларни амалга ошириш;
                            йўл ҳаракати хавфсизлигини таъминлаш мақсадида автотранспорт воситалари ҳаракатини вақтинча чеклаш ёки тўхтатишни жорий этиш бўйича тегишли таклифлар киритиш;
                            Бош бошқарма таркибидаги корхона ва ташкилотлардаги сафарбарлик тайёргарлиги бўйича улар фаолиятини назорат қилиш ва мувофиқлаштириш;
                            умумий фойдаланишдаги автомобиль йўллари давлат кадастрини юритиш;
                            автомобиль йўллари, шаҳарлар ва бошқа аҳоли пунктлари кўчаларининг ҳисобини юритиш;
                            автомобиль йўлларининг техник ҳолатига қараб автомобиль йўлларини қуриш, реконструкция қилиш ва таъмирлашнинг ҳар йилги дастурларини шакллантириш бўйича Муассисга таклифлар киритиш ва тасдиқланган дастурларни амалга ошириш;
                            хўжаликлараро қишлоқ автомобиль йўлларини, шаҳарлар, шаҳар посёлкалари, қишлоқлар ва овуллар кўчаларини таъмирлашнинг ҳар йилги дастурларини ўрнатилган тартибда шакллантириш ва амалга ошириш ишларини мувофиқлаштириш;
                            вилоят автомобиль йўллари рўйхатини шакллантириш юзасидан Муассисга таклифлар киритиш;
                            вилоят  автомобиль йўллари тармоғини такомиллаштириш ва ривожлантириш;
                            таркибидаги корхона ва ташкилотлар фаолияти мониторингини олиб бориш ва иқтисодий таҳлил қилиш, шунингдек таркибидаги корхона ва ташкилотларда молия-хўжалик фаолиятини ва мол-мулкдан фойдаланилишини текшириш;
                            қулай ва хавфсиз ҳаракатни таъминлаш учун автомобиль йўлларидан фойдаланиш, автомобиль йўлларидан фойдаланишда йўл ҳаракати хавфсизлиги ва фавқулодда вазиятлар органлари билан ўзаро ҳамкорлик қилиш;
                            фуқароларни қабул қилишни ташкил этиш, жисмоний ва юридик шахсларнинг оғзаки ва ёзма мурожаатлари ўз вақтида ва тўлиқ кўриб чиқилишини таъминлаш, улар бўйича қарорлар қабул қилиш ва ариза берувчиларга қонун ҳужжатларида белгиланган муддатда жавоблар юбориш;
                            вилоят умумий фойдаланишдаги автомобиль йўлларини сақлаш, жорий таъмирлаш, табиий офат оқибатларини олдини олиш ва бартараф этиш ҳамда жиҳозлаш (техника, машина-механизмлар, технологик ускуналарни харид қилиш) ишлари бўйича буюртмачилик вазифасини амалга ошириш ҳамда сифати устидан назоратни олиб бориш;
                            вилоят ҳудудидаги хўжаликлараро қишлоқ автомобиль йўлларининг, шаҳарлар, шаҳар посёлкалари, қишлоқлар ва овуллар кўчаларини лойиҳалаштириш, қуриш, реконструкция қилиш ва таъмирлаш ишларини мувофиқлаштириш;
                            вилоят умумий фойдаланишдаги автомобиль йўлларини жорий таъмирлаш ва сақлаш устидан техник кузатиш;
                            вилоят умумий фойдаланишдаги автомобиль йўлларини сақлаш ва жорий таъмирлашда қўлланадиган йўл-қурилиш материаллари, буюмлар ва конструкцияларнинг сифати устидан назорат қилиш;
                            вилоят ҳудудидаги хўжаликлараро қишлоқ автомобиль йўллари, шаҳарлар, шаҳар посёлкалари, қишлоқлар ва овуллар кўчалари тармоғини ривожлантириш ва такомиллаштириш юзасидан шаҳарлар ва туманларнинг бош режаларини ишлаб чиқишда иштирок этиш;
                            автомобиль йўллари соҳасида техник жиҳатдан тартибга солиш борасидаги норматив ҳужжатларни ишлаб чиқиш ва такомиллаштириш бўйича Муассисга таклифлар киритиш;
                            автомобиль йўллари соҳасидаги норматив-ҳуқуқий ҳужжатлар лойиҳаларини ишлаб чиқишда иштирок этиш;
                            автомобиль йўлларини лойиҳалаштириш, қуриш, реконструкция қилиш, таъмирлаш ва сақлаш соҳасида илмий-тадқиқот ишларини ташкил этиш ва олиб бориш;
                            йўл қурилишини прогнозлаштириш, мувофиқлаштириш ва амалга оширишнинг замонавий усуллари талабларига мувофиқ автомобиль йўллари соҳасида малакали кадрлар тайёрланишини, қайта тайёрланишини таъминлаш;
                            ўқувчиларнинг олинган назарий билимларини ишлаб чиқариш амалиётида мустаҳкамлаш мақсадида соҳага оид таълим муассасалари билан ўзаро ҳамкорлик қилиш;
                            вилоят худудидаги хўжаликлараро қишлоқ автомобиль йўллари, шаҳарлар, шаҳар посёлкалари, қишлоқлар ва овуллар кўчаларининг мавжуд тармоғини таъмирлаш бўйича маҳаллий ижро этувчи ҳокимият органлари билан келишилган ҳолда йўл таъмирлаш ишлари манзил рўйхатларини ишлаб чиқиш ва кўриб чиқиш учун Муассисга киритиш;
                            ишлаб чиқаришни техникавий ва ашёвий таъминоти, аниқ иш бажарувчини танлаш, иш хақи тўлашни ўрнатилган тартиб ва усулларни қўллаш, ишчи хизматчиларни ижтимоий ҳимоялаш, шунингдек атроф-муҳитни муҳофаза қилиш талабларини бажарилишини ва табиий ресурслардан оқилона фойдаланишни назорат қилиш;
                            умумий фойдаланишдаги автомобиль йўлларини жорий таъмирлаш ва сақлаш ишлари маблағларидан самарали фойдаланиш ва уларни автомобиль йўлларининг аҳамияти бўйича амалдаги қонунчилик талаблари асосида йўналтириш ишларини мувофиқлаштириш.
                        </p>  </p>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
export default Sservice;
