import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle3 =()=>{

    const _speech = new SpeechSynthesisUtterance();

    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }
    return(
        <section className="portfolio_details_area sec_pad" style={{maxHeight:"3000px",minHeight:"1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%"}} className="col-lg-8 " >
                <img  style={{ height: 250,width:200,float:'right'}}className="img-fluid mb_20" src={require('../../img/teams/urinbosar2.jpg')} alt=""/>
                <div onClick={speak} className="row" >
                    <div  >
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Ҳалимов Алишер Ҳамзаевич</h5>
                            <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} >2023 йил 25 январдан:
                                Навоий вилояти автомобиль йўллари бош бошқармаси бошлиғи в.в.б
                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>
                                2004-2005 йй. - Кўкча автомобил йўллардан фойдаланиш бошқармаси ишчиси</p>
                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2005-2006 йй. - Ғиждувон туман йўл хўжалиги туман пудрат таъмирлаш фойдаланиш ташкилоти
                                йўл устоси</p>
                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2006-2007 йй. - Автомагистрал йўл қурилиш бошқармаси лаборанти</p>
                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2008-2018 йй. - Навоий вилояти автомобиль йўллари худудий бош бошқармаси автомобиль йўлларини транспорт, механизация хамда техник таъминлаш бўлими бош мутахассиси</p>
                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2018-2020 йй. - Навоий вилояти автомобиль йўллари худудий бош бошқармаси автомобиль йўлларини транспорт, механизация хамда техник таъминлаш бўлими бошлиғи</p>
                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2020 -2020 йй. - Навоий вилояти автомобиль йўллари худудий бош бошқармаси бошлиқ ўринбосари</p>
                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2020 й-2023 йй.- Навоий вилояти автомобиль йўллари худудий бош бошқармаси бош мухандиси</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     25.01.2023 й ҳ.в.- Навоий вилояти автомобиль йўллари бош бошқармаси бошлиғи вазифасини вақтинча бажарувчи</p>




                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle3;
