import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle15 = () => {

    const _speech = new SpeechSynthesisUtterance();

    const speak = (event) => {
        if (window.isSpeakingEnabled) {
            _speech.text = event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);
        }
    }
    return (
        <section className="portfolio_details_area sec_pad" style={{maxHeight: "3000px", minHeight: "1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft: "8%"}} className="col-lg-8 ">
                <img style={{height: 250, width: 200, float: 'right'}} className="img-fluid mb_20"
                     src={require('../../img/teams/navbaxorBoshliq.jpg')} alt=""/>
                <div onClick={speak} className="row">
                    <div>
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Гулов Қодир Қаҳҳорович</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2023 йил 25 сентябрдан:
                                Навбахор туман йўллардан фойдаланиш давлат муассасаси
                                директори




                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2008-2013 йй. - “Навоий Халқаро Аэропорти” маъсулияти чекланган жамияти аэродром хизматида аэродром устоси </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2013-2014 йй. - Навоий вилояти Кармана тумани йўллардан фойдаланиш унитар корхонаси мутахассиси</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2014-2017 йй. - Навоий вилояти Кармана тумани йўллардан фойдаланиш унитар корхонаси йўл бўлими бошлиғи
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2017- 2023йй  - “Йўл-Лойиҳа Бюроси” маъсулияти чекланган жамияти Навоий вилояти филиали бошлиғи
                            </p>


                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2023 й-  х.в.    -  Навбахор туман йўллардан фойдаланиш давлат муассасаси директори

                            </p>

                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle15;
