import React, {Component, Fragment} from 'react';
import {Link,useLocation} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from "formik";
import AuthenticationService from "../api/AuthenticationService";
import ReCaptchaV2 from 'react-google-recaptcha'
import {REACT_APP_SITE_KEY }from '../Constants'
import LoadingScreen from "react-loading-screen";
import loading from "../img/new/loading.png";
import { useParams } from "react-router";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string'
import history from "../index";
import {withTranslation} from "react-i18next";
function gloginError()
{
    this.setState({
        loginError: true
    })
}
function gclearloginError() {
    this.setState({
        loginError: false
    })

}
function gcaptchaError() {
    this.setState({
        captchaError: true
    })
}
function gclearcaptchaError() {
    this.setState({
        captchaError: false
    })
}
function gloadingscreenOff() {
    this.setState({
        loadingscreen:false
    })
}
class PasswordResetFormStep2 extends Component{

    constructor(props) {
        super(props);
        this.state={
            username:'',
            password:'',
            password2:'',
            isVerified:false,
            loginError:false,
            captchaError:false,
            loadingscreen:false,
            token:''
        }

        this.validate=this.validate.bind(this)
        gloginError=gloginError.bind(this)
        gclearloginError=gclearloginError.bind(this)
        this.verifyCallback=this.verifyCallback.bind(this)
        this.expiredCallback=this.expiredCallback.bind(this)
        gcaptchaError=gcaptchaError.bind(this)
        gclearcaptchaError=gclearcaptchaError.bind(this)
        this.resetPassword=this.resetPassword.bind(this)
        this.loadingscreeeOff=this.loadingscreeeOff.bind(this)
        this.fsetloading=this.fsetloading.bind(this)
        gloadingscreenOff=gloadingscreenOff.bind(this)
    }

    loadingscreeeOff = () =>
    {
        this.setState({

            loadingscreen:false
        });
    }
    fsetloading()
    {
        this.setState({
            loadingscreen:true
        });
    }
    validate(values)
    {
        let errors={}

     if(!values.password){
        errors.password='Parolni kiriting!'
    }

    else if(values.password.length<8)
    {
        errors.password='Parol uzunligi 8 ta simvoldan kam bo`lmasligi kerak'
    }
    else if(values.password.length>20)
    {
        errors.password='Parol juda uzun!'
    }
    else if(values.password.search(/\d/)==-1)
    {
        errors.password='Parol katta va kichik harflar,son va simvollardan iborat bo`ishi kerak'

    }
    else if(values.password.search(/[A-Z]/)==-1)
    {
        errors.password='Parol katta va kichik harflar,son va simvollardan iborat bo`ishi kerak'

    }
    else if(values.password.search(/\W/)==-1)
    {
        errors.password='Parol katta va kichik harflar,son va simvollardan iborat bo`ishi kerak'

    }
    else if(values.password.search(/[a-z]/)==-1)
    {
        errors.password='Parol katta va kichik harflar,son va simvollardan iborat bo`ishi kerak'

    }
    else if(values.password!==values.password2)
     {
         errors.password='Parollar bir xil emas'
     }
        return errors
    }

    resetPassword(values)
    {
        let resetpasswordform={
            token:this.state.token,
            password:values.password
             }

        if(this.state.isVerified)
        {
            this.fsetloading()

            gclearcaptchaError()

            AuthenticationService.resetPasswordFinal(resetpasswordform).then(
                (response)=>
            {
                this.loadingscreeeOff()
                gclearloginError()
                history.push("/successpasswordreset")
            }).catch(function (error){
                gloadingscreenOff()
                gloginError()
            })
        }
        else{
            gloadingscreenOff()
            gcaptchaError()
        }
    }

    verifyCallback(response)
    {
        if(response)
            this.setState({
                isVerified:true
            })
    }

    expiredCallback()
    {
        this.setState(
                {
                    isVerified:false
                }
            )
        gloadingscreenOff()
    }

    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
        const params = new URLSearchParams(window.location.search)
        let token = params.get('token')
        this.setState({
            token:token
        })

    }
    render() {



    return(
<>
        <section className="sign_in_area bg_color sec_pad">
            {this.state.loadingscreen&&   <LoadingScreen
                loading={true}
                bgColor='#f1f1f1'
                spinnerColor='#9ee5f8'
                textColor='#676767'
                logoSrc={loading}
                text='Yuklanmoqda...'
                position='fixed'
            >

                <div>Loadable content</div>
            </LoadingScreen>}
            <div className="container">
                <div className="sign_info">
                    <div className="row">

                        <div className="col-lg-7">
                            <div className="login_info">
                                <h2 onClick={this.speak} className="f_p f_600 f_size_24 t_color3 mb_40">{this.props.t('parolniT')}</h2>

                                <Formik initialValues={{ username: "", email: "",password:"" }}
                                        onSubmit={this.resetPassword}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validate={this.validate}
                                    // enableReinitialize={true}
                                >{
                                    (props) => (

                                <Form  className="login-form sign-in-form">
                                    <ErrorMessage name='password' component='div'
                                                  className="alert alert-warning"/>
                                    {this.state.loginError&&<div onClick={this.speak} className="alert alert-warning">{this.props.t('foydalanuvchiNomiYPX')}</div>}
                                    {this.state.captchaError&&<div onClick={this.speak} className="alert alert-warning">{this.props.t('captchaniT')}</div>}
                                    <div onClick={this.speak} className="form-group text_box">
                                        <label className="f_p text_c f_400">{this.props.t('yangiP')}</label>
                                        <Field type="password" placeholder="******" name="password"/>
                                    </div>
                                    <div onClick={this.speak} className="form-group text_box">
                                        <label className="f_p text_c f_400">{this.props.t('yangiPQK')}</label>
                                        <Field type="password" placeholder="******" name="password2"/>
                                    </div>
                                    <div onClick={this.speak} className="form-group text_box">
                                        <label className="f_p text_c f_400">CAPTCHA</label>
                                        <ReCaptchaV2 sitekey={REACT_APP_SITE_KEY}
                                                     onChange ={this.verifyCallback}
                                                     onExpired={this.expiredCallback}
                                        />
                                    </div>
                                    <div className="extra mb_20">
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn_three">OK</button>
                                        {/*<div className="social_text d-flex ">*/}
                                        {/*    <div className="lead-text">Don't have an account?</div>*/}
                                        {/*    <ul className="list-unstyled social_tag mb-0">*/}
                                        {/*        <li><a href="/#"><i className="ti-facebook"></i></a></li>*/}
                                        {/*        <li><a href="/#"><i className="ti-twitter-alt"></i></a></li>*/}
                                        {/*        <li><a href="/#"><i className="ti-google"></i></a></li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}}
export default withTranslation()(PasswordResetFormStep2);
