import React, {Component} from 'react';
import {Link} from "react-router-dom";


class CloudServiceItem extends Component {
    render(){
        let {icon, sTitle, Sp, sbtn,link} = this.props;
        return(
            <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.4s">
                <div className="software_featured_item text-center mb_20">
                    <div className="s_icon">
                        <img src={require("../../img/home5/icon_shape.png")} alt=""/>
                        <img className="icon" src={require ("../../img/home5/" + icon)} alt=""/>
                    </div>
                    <Link to={link} className="learn_btn"><h3 className="f_600 t_color3">{sTitle}</h3></Link>
                </div>
            </div>
        )
    }
}
export default CloudServiceItem
