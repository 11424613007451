import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';
import Person from "@material-ui/icons/Person";
import AuthenticationService from "../api/AuthenticationService";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Flags from 'country-flag-icons/react/3x2'
import i18next from "i18next";
import Reveal from 'react-reveal/Reveal/';
import ReactDOM from 'react-dom'
import Search from "@material-ui/icons/Search";
class CustomNavbar extends Component {


    constructor(props) {
        super(props);
        this.state={
            loggedOut:false,
            isClicked:true,

            lang:'ru-RU'

        }

        this.logout=this.logout.bind(this)
    }

    changeSiteLanguageToRu=()=>{
        i18next.changeLanguage('ru')
    }
    changeSiteLanguageToKirill=()=>{
        i18next.changeLanguage('kirill')
    }
    changeSiteLanguageToUz=()=>{
        i18next.changeLanguage('uz')
    }
    speakOn=(event)=>{
        this.setState({

        })
        if(window.isSpeakingEnabled)
        {
            window.speechSynthesis.cancel()
        }
        window.isSpeakingEnabled=!window.isSpeakingEnabled
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = this.state.lang;
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }

    changetogray=()=>{

        this.setState({
            isClicked:!this.state.isClicked
        })

        var list = document.getElementsByClassName('container')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByTagName('p')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }

        list = document.getElementsByClassName('iitem')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('grey')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }


        list = document.getElementsByClassName('grey')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('new_footer_area')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('breadcrumb_area')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }

        list = document.getElementsByClassName('widget')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('table-responsive')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }

        list = document.getElementsByClassName('btn')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }

        list = document.getElementsByClassName('collapse')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('navbar')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('slick-list')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
        list = document.getElementsByClassName('slick-dots')
        for (let item of list) {
            ReactDOM.findDOMNode(item).style.filter = this.state.isClicked?'grayscale(100%)' : 'grayscale(0)'
        }
    }


    logout()
    {
        AuthenticationService.logout()
        this.setState({
            loggedOut:true
        })
    }

    render() {
        const{t}=this.props.translation
        let user=''
        const isUserLoggedIn=AuthenticationService.isUserLoggedIn();
        if(isUserLoggedIn)
            user=AuthenticationService.getLoggedInUserName()
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky  top={0} innerZ={9999} activeClass="navbar_fixed">
                <header  id="grey" className="header_area">
                    <nav className={`navbar navbar-expand-lg menu_one ${mClass}`} >
                        {/*<div className={` ${cClass}`} style={{maxWidth:"1400px",width:"1400px"}} >*/}
                        <div style={{margin:"auto",display:"flex",float:"left"}} >
                            {/*<div style={{width:"400px",paddingTop:"20px"}} >*/}
                            <div style={{paddingTop:"20px",paddingRight:"20px"}} >
                                <Link  to="/">
                                    {/*<div style={{fontSize:"18px",color:"black"}} >Bosh Sahifa</div>*/}
                                    {i18next.language==='ru-RU'&&<Reveal effect="fadeInUp" duration={2400}><h4  onClick={this.speak} style={{color:"black",fontSize:"12px"}}> {t('organisation1')}<br/>{t('organisation2')} </h4></Reveal>}
                                    {i18next.language==='ru'&&<Reveal effect="fadeInUp" duration={2400}><h4 onClick={this.speak}style={{color:"black",fontSize:"12px"}}> {t('organisation1')}<br/>{t('organisation2')} </h4></Reveal>}
                                    {i18next.language==='uz'&&<Reveal effect="fadeInUp" duration={2400}><h4 onClick={this.speak}style={{color:"black",fontSize:"15px"}}> {t('organisation1')}<br/>{t('organisation2')} </h4></Reveal>}
                                    {i18next.language==='kirill'&& <Reveal effect="fadeInUp" duration={2400}><h4 onClick={this.speak}style={{color:"black",fontSize:"15px"}}> {t('organisation1')}<br/>{t('organisation2')} </h4></Reveal>}
                                    {/*<img style={{width:"120px",height:"55px"}}src={require('../img/logoMainSvg2.svg')}/>*/}
                                    {/*<img style={{width:"120px",height:"55px"}}src={require('../img/svg222.svg')}/>*/}
                                </Link>

                                {/*<Reveal effect="fadeInUp" duration={2400}> <span onClick={this.speak}style={{marginTop:"0px",paddingTop:"0px",color:"orange",fontSize:"12px"}}>{t('test')}</span></Reveal>*/}
                            </div>
                            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent" >
                                <ul className={`navbar-nav menu ml-auto ${nClass}`} >
                                    {/* <li className="nav-item dropdown submenu mega_menu mega_menu_two">
                                    <Link to="./" className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                        Bosh sahifa
                                    </Link>

                                </li>*/}

                                    <li  className="dropdown submenu nav-item">
                                        <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{t('korxonaH')}</Link>
                                        <ul role="menu" className=" dropdown-menu">
                                            <li onClick={this.speak} className="nav-item"><NavLink  className="nav-link" to='/About'>{t('aboutUs')}</NavLink></li>
                                            <li onClick={this.speak} className="nav-item"><NavLink  className="nav-link" to='/Team'>{t('authority')}</NavLink></li>
                                            <li onClick={this.speak} className="nav-item"><NavLink  className="nav-link" to='/TeamRegion'>{t('authorityRegion')}</NavLink></li>
                                            <li onClick={this.speak} className="dropdown submenu nav-item">
                                                <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{t('gallery')}</Link>
                                                <ul role="menu" className=" dropdown-menu">
                                                    <li onClick={this.speak}className="nav-item">
                                                        <Link title="Pages" className="nav-link"  to='/Portfolio-2col'>{t('fotoGalereya')}</Link>
                                                    </li>
                                                    <li onClick={this.speak}className="nav-item">
                                                        <NavLink exact title="Service Details" className="nav-link" to='/VideoGallery'>{t('videoGalereya')}</NavLink>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li onClick={this.speak}className="nav-item"><NavLink title="" className="nav-link" to="/news">{t('news')}</NavLink></li>
                                            {/*<li onClick={this.speak}className="nav-item"><NavLink className="nav-link" to='/ustavd'>{t('ustavFVUT')}</NavLink></li>*/}
                                            <li onClick={this.speak}className="nav-item"><NavLink  className="nav-link" to='/ustav'>{t('korxonaU')}</NavLink></li>
                                            <li onClick={this.speak}className="nav-item"><NavLink  className="nav-link" to='/strorg'>{t('korxonaTT')}</NavLink></li>
                                            <li onClick={this.speak}className="nav-item"><NavLink className="nav-link" to='/vacancy'>{t('bushIU')}</NavLink></li>
                                            <li onClick={this.speak}className="nav-item"><NavLink  className="nav-link" to='/importantf'>{t('muhimF')}</NavLink></li>
                                            <li onClick={this.speak}className="nav-item"><NavLink exact title="Faq" className="nav-link" to='/Faq'>{t('faq')}</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown submenu nav-item">
                                        <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{t('korxonaF')}</Link>
                                        <ul role="menu" className=" dropdown-menu">
                                            <li onClick={this.speak}className="nav-item"><NavLink exact title="About" className="nav-link" to='/fields'>{t('faoliyatiningAYVM')}</NavLink></li>

                                            <li onClick={this.speak}className="nav-item"><NavLink exact title="Team" className="nav-link" to='/performedw'>{t('bajariladiganI')}</NavLink></li>


                                        </ul>
                                    </li>
                                    {/*<li className="dropdown submenu nav-item">*/}
                                    {/*    <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{t('hisobotlar')}</Link>*/}
                                    {/*    <ul role="menu" className=" dropdown-menu">*/}
                                    {/*        <li onClick={this.speak}className="nav-item"><NavLink exact title="About" className="nav-link" to='/balancesh'>{t('buxgalteriyaB')}</NavLink></li>*/}
                                    {/*        <li onClick={this.speak}className="nav-item"><NavLink exact title="Team" className="nav-link" to='/financialr'>{t('moliyaviyN')}</NavLink></li>*/}
                                    {/*        <li onClick={this.speak}className="nav-item"><NavLink exact title="Team" className="nav-link" to='/purchases'>{t('xaridlarTM')}</NavLink></li>*/}




                                    {/*    </ul>*/}
                                    {/*</li>*/}
                                    {/*<li className="dropdown submenu nav-item">*/}
                                    {/*    <Link to="./" title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{t('services')}</Link>*/}
                                    {/*    <ul role="menu" className=" dropdown-menu">*/}
                                    {/*        <li className="nav-item"><NavLink exact title="Service" className="nav-link" to='/Service'>{t('services')}</NavLink></li>*/}
                                    {/*        <li className="nav-item"><NavLink exact title="Service Details" className="nav-link" to='/ServiceDetails'>{t('serviceDetails')}</NavLink></li>*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}




                                    {/* <li className="nav-item dropdown submenu">
                                    <a className="nav-link dropdown-toggle" href=".#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Blog
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li className="nav-item"><NavLink to="/Bloglist" className="nav-link">Blog List</NavLink></li>
                                        <li className="nav-item"><NavLink to="/BlogGridPage" className="nav-link">Blog Grid</NavLink></li>
                                        <li className="nav-item"><NavLink to="/BlogSingle" className="nav-link">Blog Single</NavLink></li>
                                    </ul>
                                </li>*/}
                                    <li onClick={this.speak}className="nav-item"><NavLink title="" to className="nav-link" to="/Contact">{t('contacts')}</NavLink></li>
                                    {isUserLoggedIn&&<li className="dropdown submenu nav-item"><Link title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to="#">{user} <Person fontSize="large"/></Link>
                                        <ul role="menu" className=" dropdown-menu">
                                            <li onClick={this.speak}className="nav-item"><NavLink title="Portfolio 2" className="nav-link" to='/UserSettings'>{t('settings')}</NavLink></li>
                                            <li onClick={this.speak}className="nav-item"><Button  className="nav-link" onClick={this.logout}>{t('logOut')}</Button></li>

                                            {/*<li className="nav-item"><NavLink title="PortfolioSingle" className="nav-link" to='/PortfolioSingle'>Portfolio Single</NavLink></li>*/}
                                        </ul>
                                    </li>}
                                    {!isUserLoggedIn&&<li onClick={this.speak}className="nav-item" style={{marginLeft:"35px"}} ><NavLink  title="" className="nav-link" to="/signin">{t('signIn')}<Person fontSize="large"/> </NavLink></li>}
                                    <li onClick={this.speak} className="nav-item"  style={{marginLeft:"20px"}}><NavLink  title="" className="nav-link" to="/search?#"><Search fontSize={'large'}/> </NavLink></li>                                    {/*<li className="dropdown submenu nav-item"><Link title="Pages" className="dropdown-toggle nav-link" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" to="#">Galereya</Link>*/}
                                    {/*    <ul role="menu" className=" dropdown-menu">*/}
                                    {/*        <li className="nav-item"><NavLink title="Portfolio 2" className="nav-link" to='/Portfolio-2col'>Asvalta beton zavod</NavLink></li>*/}
                                    {/*        <li className="nav-item"><NavLink title="Portfolio 3" className="nav-link" to='/Portfolio-3col'>Sheben Zavod</NavLink></li>*/}
                                    {/*        <li className="nav-item"><NavLink title="Portfolio Fullwidth" className="nav-link" to='/Portfolio-fullwidth-4col'>Drop Zavod</NavLink></li>*/}
                                    {/*        /!*<li className="nav-item"><NavLink title="PortfolioSingle" className="nav-link" to='/PortfolioSingle'>Portfolio Single</NavLink></li>*!/*/}
                                    {/*    </ul>*/}
                                    {/*</li>*/}


                                </ul>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <button className="btn nav-item" style={{width:"50px",height:"30px",fontSize:"15px"}} onClick={this.changeSiteLanguageToUz}><img style={{width:"30px",height:"25px",fontSize:"15px"}} src={require('../img/flaguz.png')}/>UZ</button>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <button className="btn nav-item"style={{width:"50px",height:"30px",fontSize:"15px"}} onClick={this.changeSiteLanguageToRu}><img style={{width:"30px",height:"25px",fontSize:"15px"}} src={require('../img/flagru.png')}/>RU</button>
                                <span>&nbsp;&nbsp;&nbsp;</span>
                                <button className="btn nav-item" style={{width:"50px",height:"30px",fontSize:"15px"}} onClick={this.changeSiteLanguageToKirill}><img style={{width:"30px",height:"25px",fontSize:"15px"}} src={require('../img/flaguz.png')}/>ЎЗ</button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <button to="#"  className="btn nav-item nav-link" style={{fontSize:"18px"}} onClick={this.changetogray}><i className="fa fa-low-vision" aria-hidden="true"></i></button>

                                {!window.isSpeakingEnabled&&<button to="#"  className="btn nav-item nav-link" style={{fontSize:"18px"}} onClick={this.speakOn}><i className="fa fa-microphone navbar-btn " aria-hidden="true"></i></button>}
                                {window.isSpeakingEnabled&&<button to="#"  className="btn nav-item nav-link" style={{fontSize:"18px",color:"red"}} onClick={this.speakOn}><i className="fa fa-microphone navbar-btn " aria-hidden="true"></i></button>}


                                {/*<a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a>*/}
                            </div>


                        </div>

                    </nav>
                </header>
            </Sticky>
        );
    }
}

export default ()=>{
    const {t} = useTranslation();
    return (
        <CustomNavbar translation={{t}} />
    )
};
