import React, {useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import SignInForm from '../components/SignInForm';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {history} from "./Home";
import {Router} from "react-router";
import {Switch, Route, useLocation} from "react-router-dom";
import SignUpForm from "../components/SignUpForm";
import {useTranslation} from "react-i18next";

const SignIn = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(

        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            {/*<Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle="Sign In" />*/}

            <Route path="/signin" component={SignInForm}/>
            {/*<FooterTwo FooterData={FooterData}/>*/}
        </div>

    )
}
export default SignIn;
