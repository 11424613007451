import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import BranchService from "../../api/BranchService";
class BlogGridItem extends Component{


    constructor(props) {
        super(props);
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }
    render(){
        let{id,date, month, Title, description, btn, comment, image}=this.props;
        let a="/BlogSingle?id="+id.toString()
        return(
            <div className="col-lg-6">
                <div className="blog_list_item blog_list_item_two">

                    <Link to={a} className="post_date">
                        <h2>{date} <span>{month}</span></h2>
                    </Link>
                    <Link to={a}>
                        <img className="img-fluid" src={image} alt=""/>
                    </Link>
                    <div className="blog_content">
                        <Link to={a}>
                            <h5 onClick={this.speak}className="blog_title">{Title}</h5>
                        </Link>
                        <p onClick={this.speak}>{description}</p>
                        <div className="post-info-bottom">

                            <Link to={a} className="learn_btn_two">
                                {btn} <i className="arrow_right"></i>
                            </Link>

                            {/*<Link className="post-info-comments" to="/#">*/}
                                <i className="icon_comment_alt" aria-hidden="true"></i>
                                <span>{comment} Kommentariyalar</span>
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default BlogGridItem;
