import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle6 =()=>{

    const _speech = new SpeechSynthesisUtterance();

    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }
    return(
        <section className="portfolio_details_area sec_pad" style={{maxHeight:"3000px",minHeight:"1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%"}} className="col-lg-8 " >
                <img  style={{ height: 250,width:200,float:'right'}}className="img-fluid mb_20" src={require('../../img/teams/KonimexDirektor.jpg')} alt=""/>
                <div onClick={speak} className="row" >
                    <div  >
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Хошимов Озод Элмуродович</h5>
                            <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} >2024 йил 8 апрелдан:
                                Конимех туман йўллардан фойдаланиш давлат муассасаси директори

                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>
                                2003-2004 йй. - Бухоро вилояти Ғиждувон тумани Давлат солик инспекцияси хисобга олиш, тахлил ва солиқ статистикаси бўлими «Солиқ тушумларини ҳисобга олиш шўъбаси Давлат солиқ инспектори</p>
                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2004-2009 йй. - Бухоро вилояти Ғиждувон тумани Давлат солик инспекцияси Жисмоний шахсларни солиққа тортиш ва назорат бўлими солик инспектори</p>
                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2009-2012 йй. - Бухоро вилояти Ғиждувон тумани Давлат солиқ инспекцияси Жисмоний шахсларни соликка тортиш ва назорат бўлими  давлат солиқ инспекторлари фаолиятини ташкил этиш ва якка тартибдаги тадбиркорларни солиққа тортиш шўъбаси бошлиғи</p>
                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2012-2014 йй. - Кармана туман йўл хўжалиги пудрат таъмирлаш фойдаланиш корхонаси йўл ишлари истиқбол режаси ва шартномалар буйича мутахассис</p>
                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2014-2014 йй. - Кармана туман йўл хўжалиги пудрат таъмирлаш фойдаланиш корхонаси директори</p>
                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2014-2019 йй. - Кармана туман йўл хўжалиги пудрат таъмирлаш фойдаланиш корхонаси 219-217-йўл бўлими бошлиғи</p>
                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2019-2024 йй. - Кармана туман йўллардан  фойдаланиш унитар корхонаси Иш юритувчиси</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>     2024 й -  ҳ.в. - Конимех туман йўллардан  фойдаланиш унитар корхонаси директори и</p>




                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle6;
