import React, {Component, useEffect, useState} from 'react';
import GalleryPictureService from "../../api/GalleryPictureService";
import {useLocation} from "react-router";
import {API_URL} from "../../Constants";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {useTranslation} from "react-i18next";

 const Portfoliosingle =(props)=>{
     const _speech = new SpeechSynthesisUtterance();

     const speak=(event)=>{
         if(window.isSpeakingEnabled)
         {
             _speech.text =event.currentTarget.textContent;
             _speech.lang = 'ru-RU'
             window.speechSynthesis.speak(_speech);}
     }
     const { t, i18n } = useTranslation();
    const location=useLocation()
    const queryString = require('query-string');
    const parsed = queryString.parse(location.search);
    const [any,setAny]=useState('')
    const[familyPictures,setFamilyPictures]=useState([])
    useEffect(()=> {

        GalleryPictureService.getAllFamilyGalleryPictures(parsed.id).then(response=>{
            setFamilyPictures(response.data)
            setAny(response.data[0])

        })
    }, [])

     const monthNames = ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun",
         "Iyul", "Avgust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"
     ];
     const date=new Date(any.createdAt)
    return(
        <section className="portfolio_details_area sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="portfolio_details_info pr_50">

                            <h5 onClick={speak}className="f_700 f_p f_size_20 t_color3 mb-30">{t('tavsif')}:</h5>
                            <p onClick={speak}className="f_400 f_size_15 mb-0" style={{color:'black'}}>{any.description}</p>
                            <div className="portfolio_category mt_60">

                                <div className="p_category_item mb-30">
                                    <h6 onClick={speak} className="f_p f_size_15 f_400 t_color3 mb-0 l_height28">Yuklangan Sana: </h6>
                                    <p onClick={speak}className="f_size_15 f_400 mb-0" style={{color:'black'}}>{monthNames[date.getMonth()]} {date.getDay()}, {date.getFullYear()}</p>
                                </div>


                                <div className="p_category_item">
                                    <p onClick={speak}className="f_400 f_size_15 mb-0">{t('ulashish')}:</p>
                                    <div className="social_icon">
                                        <a href=".#"><i className="ti-facebook"></i></a>
                                        <a href=".#"><i className="ti-twitter-alt"></i></a>
                                        <a href=".#"><i className="ti-google"></i></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="image portfolio_details_gallery" id='image'>
                            {
                                familyPictures.map((fp,i)=>


                                        <img  className="img-fluid mb_20" src={`${API_URL}/api/files/${fp.minioImageId}`}alt=""/>
                                                    )

                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Portfoliosingle;
