import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {API_URL} from "../../Constants";

class Portfolio2griditem extends Component{
    render(){
        let {pImg, ptitle, colClass ,family} = this.props;
        let a="/GalleryPicture?id="+family
        return(
            <div className={`portfolio_item mb-30 ${colClass}`}>
                <div className="portfolio_img">
                    <img src={`${API_URL}/api/files/${pImg}`} alt=""/>
                    <Link to={a} className="portfolio-title">
                    <div className="hover_content">
                        <div className="portfolio-description leaf">
                            <Link to={a} className="portfolio-title">
                                <h3 className="f_500 f_size_20 f_p">{ptitle}</h3>
                            </Link>

                        </div>
                    </div>
                    </Link>
                </div>
            </div>

        )
    }
}
export default Portfolio2griditem;
