import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from "formik";
import AuthenticationService from "../api/AuthenticationService";
import {useTranslation} from "react-i18next";



class SignInFrom extends Component{

    constructor(props) {
        super(props);
        this.state={
            username:'',
            password:'',
            checked:false,
            loginError:false
        }
        this.signin=this.signin.bind(this)
        this.validate=this.validate.bind(this)

    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }
    validate(values)
    {
        let errors={}

        if(!values.username){
            errors.username='Foydalanuvchi nomini kiriting!'
        }



        else if(!values.password){
            errors.password='Parolni kiriting!'
        }



        return errors
    }


    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
    }

    signin(values)
    {
        let user={
            username:values.username,
            password:values.password
        }

        if(values.checked)
        {
            AuthenticationService.signin(user).then(
                (response)=>{

                    AuthenticationService.localregisterSuccessfulLoginForJwt(response.data.username,response.data.accessToken)

                })
                .catch((response)=>{
                    this.setState({
                        loginError:true
                    })
                })
        }
        else
        {
        AuthenticationService.signin(user).then(
            (response)=>{

                AuthenticationService.sessionregisterSuccessfulLoginForJwt(response.data.username,response.data.accessToken)
            })
            .catch((response)=>{ this.setState({
                loginError:true
            })})}

    }

    render() {

        const{t}=this.props.translation

    return(
<>
        <section className="sign_in_area bg_color sec_pad">
            <div className="container">
                <div className="sign_info">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="sign_info_content">
                                <h3 onClick={this.speak} className="f_p f_600 f_size_24 t_color3 mb_40">{t('royxatdanOtmaganmisiz')}</h3>
                                <h2 onClick={this.speak}className="f_p f_400 f_size_30 mb-30">{t('uHolda')} <br/> <span className="f_700">{t('tizim')}</span> {t('royxatidan')} <br/> {t('oting')}</h2>
                                {/*<ul className="list-unstyled mb-0">*/}
                                {/*    <li><i className="ti-check"></i> Premium Access to all Products</li>*/}
                                {/*    <li><i className="ti-check"></i> Free Testing Tools</li>*/}
                                {/*    <li><i className="ti-check"></i> Unlimited User Accounts</li>*/}
                                {/*</ul>*/}
                                <Link onClick={this.speak}to='/signup' className="btn_three sign_btn_transparent">{t('royxatdanOtish')}</Link>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="login_info">
                                <h2 onClick={this.speak}className="f_p f_600 f_size_24 t_color3 mb_40">{t('tizimgaKirish')}</h2>

                                <Formik initialValues={{ username: "", email: "",password:"",checked:false }}
                                        onSubmit={this.signin}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validate={this.validate}
                                    // enableReinitialize={true}
                                >{
                                    (props) => (

                                <Form  className="login-form sign-in-form">
                                    <ErrorMessage name='username' component='div'
                                                  className="alert alert-warning"/>

                                    <ErrorMessage name='password' component='div'
                                                  className="alert alert-warning"/>


                                    {this.state.loginError&&<div className="alert alert-warning">{t('foydalanuvchiNomiYPX')}</div>}

                                    <div onClick={this.speak}className="form-group text_box">
                                        <label className="f_p text_c f_400">{t('e-mailYokiFN')}</label>
                                        <Field type="text" name="username" placeholder="foydalanuvchi@gmail.com"/>
                                    </div>
                                    <div onClick={this.speak}className="form-group text_box">
                                        <label className="f_p text_c f_400">{t('parol')}</label>
                                        <Field type="password" name="password" placeholder="******"/>
                                    </div>
                                    <div className="extra mb_20">
                                        <div onClick={this.speak}className="checkbox remember">
                                            <label>
                                                <Field type="checkbox" name="checked" /> {t('eslabQolish')}
                                            </label>
                                        </div>

                                        <div onClick={this.speak}className="forgotten-password">
                                            <a href="/forgot-password">{t('parolniUnutdingizmi')}</a>
                                        </div>
                                    </div>
                                    <div onClick={this.speak}className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn_three">{t('tizimgaKirish')}</button>
                                        {/*<div className="social_text d-flex ">*/}
                                        {/*    <div className="lead-text">Don't have an account?</div>*/}
                                        {/*    <ul className="list-unstyled social_tag mb-0">*/}
                                        {/*        <li><a href="/#"><i className="ti-facebook"></i></a></li>*/}
                                        {/*        <li><a href="/#"><i className="ti-twitter-alt"></i></a></li>*/}
                                        {/*        <li><a href="/#"><i className="ti-google"></i></a></li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}}
export default ()=>{
    const {t} = useTranslation();
    return (
        <SignInFrom translation={{t}} />
    )
};
