import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import Reveal from 'react-reveal/Reveal'
import {useTranslation} from "react-i18next";
import Flags from 'country-flag-icons/react/3x2'
import i18next from "i18next";
import FooterData from "./FooterData";
import BranchService from "../../api/BranchService";
import ReCaptchaV2 from 'react-google-recaptcha'
import {REACT_APP_SITE_KEY} from "../../Constants";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import SubscribeService from "../../api/SubscribeService";
import validator from 'validator'
class Footer extends Component {

    constructor() {
        super();
        this.state={

            email:'',
            isVerified:false,
        }
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }
    verifyCallback=(response)=>
    {
        if(response)
            this.setState({
                isVerified:true
            })

    }
    expiredCallback=()=>
    {
        this.setState(
            {
                isVerified:false
            }
       )
    }
    subscribe=(event)=>{
        const{t}=this.props.translation
        event.preventDefault()

        toast.configure()
        if(this.state.email!=='')
        {
            if(validator.isEmail(this.state.email)) {
                if (this.state.isVerified) {
                    SubscribeService.subscribe222(this.state.email).then((res) => {
                        if (res.status === 226) {
                            toast.warning(t("emailARO"))
                        } else {
                            toast.success(t("successfulS"))
                            window.scrollTo({top: 0, behavior: "smooth"})
                        }
                    }).catch((err) => {

                    })
                } else {
                    toast.warning(t("captchaniT"))
                }
            }
            else {
                toast.warning(t("invalidEmail"))
            }
        }
        else {toast.warning(t("elektronPochtaMK"))}
    }
    render(){
        const{t}=this.props.translation
        let FooterData = this.props.FooterData;
        return(
            <footer className="new_footer_area bg_color">
                <div className="new_footer_top">
                    <div className="container">
                        <div className="row">
                                        <Reveal effect="fadeInLeft" duration={500} >
                                            <div className="col-lg-3 col-md-6" >
                                                <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s">
                                                    <h3 onClick={this.speak} className="f-title f_600 t_color f_size_18">{t('aloqadaBoling')}</h3>
                                                    <p onClick={this.speak} >{t('yangiliklarniOtkazibYubormang')}</p>
                                                    <form onClick={this.speak}  action="#" className="f_subscribe_two mailchimp" method="post">
                                                        <input type="text" name="EMAIL" className="form-control memail" onChange={(event)=>this.setState({email:event.target.value})} placeholder="Email"/>
                                                        <div onClick={this.speak} >
                                                            <ReCaptchaV2 sitekey={REACT_APP_SITE_KEY}
                                                                         onChange ={(r)=>this.verifyCallback(r)}
                                                                         onExpired={(r)=>this.expiredCallback(r)}
                                                            />
                                                        </div>
                                                        <button  className="btn btn_get btn_get_two" onClick={(event)=>this.subscribe(event)}>{t('obunaBolish')}</button>
                                                        <p className="mchimp-errmessage" style={{display: "none"}}></p>
                                                        <p className="mchimp-sucmessage" style={{display: "none"}}></p>
                                                    </form>

                                                </div>
                                            </div>
                                        </Reveal>



                            <Reveal effect="fadeInLeft" duration={500} key={1}>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">

                                                <ul className="list-unstyled f_list">
                                                    <li onClick={this.speak}  ><Link to='/About'>{t('aboutUs')}</Link></li>
                                                    <li onClick={this.speak} ><Link to='/search'>{t('qidiruv')}</Link></li>
                                                    <li onClick={this.speak} ><Link to='/vacancy'>{t('bushIU')}</Link></li>
                                                    <li onClick={this.speak} ><Link to='/Contact'>{t('contacts')}</Link></li>


                                                </ul>
                                            </div>
                                        </div>
                            </Reveal>


                            {
                                FooterData.SocialLinks.map(widget=>{
                                    return(
                                        <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                <h3 onClick={this.speak} className="f-title f_600 t_color f_size_18">{t('ijtimoiyTarmoqlar')}</h3>
                                                <div className="f_social_icon">
                                                    {
                                                        widget.menuItems.map(item =>{
                                                            return(
                                                                <Link to="/" key={item.id}><i className={item.icon}></i></Link>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        </Reveal>
                                    )
                                })
                            }
                        </div>

                    </div>
                    <div className="footer_bg">
                        <div className="footer_bg_one"></div>
                        <div className="footer_bg_two"></div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-7">
                                <p onClick={this.speak} className="mb-0 f_400">{t('copywrite')}</p>
                            </div>
                           {/* <div className="col-lg-6 col-sm-5 text-right">
                                <p>Made with <i className="icon_heart"></i> in <a href="/#">DroitThemes</a></p>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default ()=>{
    const {t} = useTranslation();
    return (
        <Footer translation={{t}} FooterData={FooterData} />
    )
};

