import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {useTranslation, withTranslation} from "react-i18next";
const SuccessfulPasswordReset = () => {
const _speech = new SpeechSynthesisUtterance();
    const { t, i18n } = useTranslation();

const speak=(event)=>{
    if(window.isSpeakingEnabled)
    {
        _speech.text =event.currentTarget.textContent;
        _speech.lang = 'ru-RU'
        window.speechSynthesis.speak(_speech);}
}
    return(

  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/success3.png")} alt="" />
          <h2 onClick={speak}>{t('parolMO')}</h2>
          <p onClick={speak}>
              {t('boshSO')} ...{" "}
          </p>

          <a onClick={speak}href="/" className="about_btn btn_hover">
              {t('boshSQ')} <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
    <FooterTwo FooterData={FooterData} />
  </div>
);}
export default SuccessfulPasswordReset;
