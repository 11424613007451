import React, {Component, useEffect, useState} from 'react';
import CurrencyRatesService from "../../api/CurrencyRatesService";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import ReactWeather, { useOpenWeather } from 'react-open-weather';
import axios from "axios";
import Geolocation from "../../api/Geolocation";

class Blogrightsidebar2 extends Component{

    constructor(props) {
        super(props);
        this.state={
            currencyRates:[],
            rate01:'',
            lat:'',
            long:'',
            geo:[]

        }
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }

    componentDidMount() {
        CurrencyRatesService.getCUrrency().then((response) => {
            this.setState({
                currencyRates: response.data,
                rate01: response.data[0]
            })
        })

        this._speech = new SpeechSynthesisUtterance();

    }



    render(){
        const { data, isLoading, errorMessage }=this.props.weatherr
        const {t}=this.props.translation
        let ServiceData = this.props.ServiceData;
        return(
            <div className="blog-sidebar">

                <div onClick={this.speak}className="widget sidebar_widget widget_recent_post mt_60">
                    <div className="widget_title">
                        <h3 onClick={this.speak} className="f_p f_size_20 t_color3">{t('obhavo')}</h3>
                        <div className="border_bottom"></div>
                </div>
                <ReactWeather
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    data={data}
                    lang="ru"
                    locationLabel="Navoiy"
                    unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                    showForecast
                /></div>
                <div className="widget sidebar_widget widget_recent_post mt_60">
                    <div className="widget_title">
                        <h3 onClick={this.speak}className="f_p f_size_20 t_color3">{t('valyutaKurslari')} {this.state.rate01.Date} {t('holatiga')}</h3>
                        <div className="border_bottom"></div>
                    </div>
                    {
                        this.state.currencyRates.map(post=>{
                            return(
                                <div className="media post_item" key={post.id}>
                                    <div className="media-body">
                                        <h3 onClick={this.speak}className="f_size_16 f_p f_400">{post.Nominal} {i18next.language==='uz'&& post.CcyNm_UZ}{i18next.language==='ru'&& post.CcyNm_RU}{i18next.language==='kirill'&& post.CcyNm_UZC} = {post.Rate} UZS</h3>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>


            </div>
        )
    }
}

export default ()=>{

    const {t} = useTranslation();
    const { data, isLoading, errorMessage } = useOpenWeather({
        key: '6057277f00c3fed52889f1abe246f18d',
        lat: 40.105319569073735,
        lon: 65.37402492454045,
        lang: 'ru',
        unit: 'metric', // values are (metric, standard, imperial)
    });
    return (
        <Blogrightsidebar2 translation={{t}} weatherr={{ data, isLoading, errorMessage }} />
    )
};
