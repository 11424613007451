import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from "formik";
import AuthenticationService from '../api/AuthenticationService'
import LoadingScreen from 'react-loading-screen'
import loading from '../img/new/loading.png'
import {useTranslation} from "react-i18next";
import history from "../index";

function gUsernameExists() {
    this.fUsernameExists()
}
function gEmailExists() {
    this.fEmailExists()
}
function gloadingscreenOff() {
    this.loadingscreeeOff()
}
const Loader = () => (
    <div class="divLoader" style={{display: "flex",justifyContent: "center",alignItems:"center",position:'fixed',
        padding:0,
        margin:0,
        background:"white",
        top:0,
        left:0,
        zIndex:10000000001,
        width: '100%',
        height: '100%'}}>
        <svg class="svgLoader" viewBox="0 0 100 100" width="12em" height="12em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
    </div>
);
class SignUpForm extends Component{
    constructor(props) {
        super(props);
        this.state={
            username:'',
            password:'',
            email:'',
            checked:false,
            usernameExists11:false,
            emailExists:false,
            loadingscreen:false
        }
        this.validate=this.validate.bind(this)
        gUsernameExists=gUsernameExists.bind(this)
        gEmailExists=gEmailExists.bind(this)
        this.loadingscreeeOff=this.loadingscreeeOff.bind(this)
        gloadingscreenOff=gloadingscreenOff.bind(this)
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }

    validate(values)
    {
        const{t}=this.props.translation
        let errors={}

        if(!values.username){
        errors.username=t('foydalanuvchiNominiK')
        }
        else if(values.username.search(/\W/)!=-1)
        {
            errors.username=t('foydalanuvchiNomidaSQK')

        }
        else if(values.username.length>15)
        {
            errors.username=t('foydalanuvchiNomiJU')

        }
        else if(!values.email){
            errors.email=t('elektronPochtaMK')
        }
        else if(values.email.length>35)
        {
            errors.username=t('emailNomiJU')

        }
         else if(!values.password){
            errors.password=t('parolniKiriting')
        }
         else if(!values.checked)
        {
            errors.checked=t('saytShartlariniQQ')
        }
         else if(values.password.length<8)
        {
            errors.password=t('parolUzunligi')
        }
        else if(values.password.length>20)
        {
            errors.password=t('parolJudaU')
        }
        else if(values.password.search(/\d/)==-1)
        {
            errors.password=t('parolTalabi')

        }
        else if(values.password.search(/[A-Z]/)==-1)
        {
            errors.password=t('parolTalabi')

        }
        else if(values.password.search(/\W/)==-1)
        {
            errors.password=t('parolTalabi')

        }
        else if(values.password.search(/[a-z]/)==-1)
        {
            errors.password=t('parolTalabi')

        }
        return errors
    }

    fUsernameExists =()=> {
        this.setState({
            usernameExists11:true,
            loadingscreen:false
        });
    }

    fEmailExists=()=>
    {
        this.setState({
            emailExists:true,
            usernameExists11:false,
            loadingscreen:false
        });
    }
    fEmailExistsFalse=()=>
    {
        this.setState({
            emailExists:false
        });
    }

    fsetloading=()=>
    {
        this.setState({
            loadingscreen:true
        });
    }

    loadingscreeeOff=()=>
    {
        this.setState({

            loadingscreen:false
        });
    }
    signup=(values)=>
    {
      let user={
          username:values.username,
          email:values.email,
          password:values.password
      }
      AuthenticationService.signup(user).then(
          (response)=> {

              this.fsetloading()
              history.push("/afterregistration")

          }).catch((error)=>{

              if(error.response.status===303)
              {
                  this.loadingscreeeOff()
                  this.fUsernameExists()
              }

              else if(error.response.status===409)
              {
                  this.loadingscreeeOff()
                  this.fEmailExists()
              }


      })
        this.fsetloading()

    }

    render() {
        let {username,password,email,checked}=this.state
        const{t}=this.props.translation
    return(
        <section className="sign_in_area bg_color sec_pad">
            {/*{this.state.loadingscreen&&   <div style={{  position:'fixed',*/}
            {/*    padding:0,*/}
            {/*    margin:0,*/}
            {/*    top:0,*/}
            {/*    left:0,*/}
            {/*    zIndex:10000000001,*/}
            {/*    width: '100%',*/}
            {/*    height: '100%'}}><LoadingScreen*/}
            {/*    loading={true}*/}
            {/*    bgColor='#f1f1f1'*/}
            {/*    spinnerColor='#9ee5f8'*/}
            {/*    textColor='#676767'*/}
            {/*    logoSrc={loading}*/}
            {/*    text='Yuklanmoqda...'*/}
            {/*    position='fixed'*/}
            {/*>*/}
            {/*</LoadingScreen>*/}
            {/*</div>*/}
            {/*}*/}
            {this.state.loadingscreen&&<Loader/>
            }
            <div className="container">

                <div className="sign_info">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="sign_info_content">
                                <h3 onClick={this.speak} className="f_p f_600 f_size_24 t_color3 mb_40">{t('allaqachonRoyxatdanO')}</h3>
                                <h2 onClick={this.speak} className="f_p f_400 f_size_30 mb-30">{t('uHolda')}<br/> {t('tizimga')} <br/><span className="f_700">{t('kiring')}</span> </h2>
                                {/*<ul className="list-unstyled mb-0">*/}
                                {/*    <li><i className="ti-check"></i> Premium Access to all Products</li>*/}
                                {/*    <li><i className="ti-check"></i> Free Testing Tools</li>*/}
                                {/*    <li><i className="ti-check"></i> Unlimited User Accounts</li>*/}
                                {/*</ul>*/}
                                {/*<button type="submit" className="btn_three sign_btn_transparent" to>Tizimga kirish</button>*/}
                                <Link onClick={this.speak}  to="/signin" className="btn_three sign_btn_transparent">{t('tizimgaKirish')}</Link>
                            </div>
                        </div>

                        <div className="col-lg-7">
                            <div className="login_info">
                                <h2 onClick={this.speak} className="f_p f_600 f_size_24 t_color3 mb_40">{t('royxatdanOtish')}</h2>
                                <Formik initialValues={{username,password,email,checked}}
                                        onSubmit={this.signup}
                                        validateOnChange={false}
                                        validateOnBlur={false}


                                        validate={this.validate}
                                    // enableReinitialize={true}
                                >
                                    {
                                        (props) => (
                                            <Form  className="login-form sign-in-form">
                                                <ErrorMessage name='username' component='div'
                                                              className="alert alert-warning"/>
                                                <ErrorMessage name='email' component='div'
                                                              className="alert alert-warning"/>
                                                <ErrorMessage name='password' component='div'
                                                              className="alert alert-warning"/>
                                                <ErrorMessage name='checked' component='div'
                                                              className="alert alert-warning"/>

                                                {this.state.emailExists&&<div className="alert alert-warning">Ushbu Email allaqachon tizimdan ro'yxatdan o'tgan!</div>}
                                                {this.state.usernameExists11&&<div className="alert alert-warning">Siz kiritgan foydalanuvchi nomi tizimda mavjud!</div>}
                                                <div onClick={this.speak} className="form-group text_box">

                                                    <label className="f_p text_c f_400">{t('foydalanuvchiNomi')}</label>
                                                    {/*<input type="text" placeholder="User"/>*/}

                                                    <Field type="text" name="username" placeholder="User"/>

                                                </div>
                                                <div onClick={this.speak} className="form-group text_box">
                                                    <label className="f_p text_c f_400">{t('eMailM')}</label>
                                                    <Field type="email" placeholder="foydalanuvchi@gmail.com" name="email"/>
                                                </div>
                                                <div onClick={this.speak} className="form-group text_box">
                                                    <label className="f_p text_c f_400">{t('parol')}</label>
                                                    <Field type="password" placeholder="******" name="password"/>
                                                </div>
                                                <div onClick={this.speak} className="extra mb_20">
                                                    <div className="checkbox remember">
                                                        <label>
                                                            <Field type="checkbox" name="checked"/> {t('menUshbuVSSHR')}
                                                        </label>
                                                    </div>


                                                </div>
                                                <div onClick={this.speak} className="d-flex justify-content-between align-items-center">
                                                    <button type="submit" className="btn_three">{t('royxatdanOtish')}</button>
                                                    {/*<div className="social_text d-flex ">*/}
                                                    {/*    <div className="lead-text">Or Sign up Using</div>*/}
                                                    {/*    <ul className="list-unstyled social_tag mb-0">*/}
                                                    {/*        <li><a href="/#"><i className="ti-facebook"></i></a></li>*/}
                                                    {/*        <li><a href="/#"><i className="ti-twitter-alt"></i></a></li>*/}
                                                    {/*        <li><a href="/#"><i className="ti-google"></i></a></li>*/}
                                                    {/*    </ul>*/}
                                                    {/*</div>*/}
                                                </div>
                                            </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}}
export default ()=>{
    const {t} = useTranslation();
    return (
        <SignUpForm translation={{t}} />
    )
};
