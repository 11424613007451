import axios from "axios";
import {API_URL} from "../Constants";


class CurrencyRatesService {

    getCUrrency()
    {
        return axios.get('https://api.navoiyavtoyul.uz/backend/api/currency')
    }}
export default new CurrencyRatesService()
