import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle20 =()=>{

    const _speech = new SpeechSynthesisUtterance();

    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }
    return(
        <section className="portfolio_details_area sec_pad" style={{maxHeight:"3000px",minHeight:"1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%"}} className="col-lg-8 " >
                <img  style={{ height: 250,width:200,float:'right'}}className="img-fluid mb_20" src={require('../../img/teams/urinbosar4.jpg')} alt=""/>
                <div onClick={speak} className="row" >
                    <div  >
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Ҳалимов Алишер Ҳамзаевич</h5>
                            <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} >2022 йил 18 августдан :
                                Навоий вилояти Автомобиль йуллари бош бошкармаси бошлик ўринбосари

                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>
                                1999-2003 йй.  - Тошкент автомобил ва йуллар институти, талаба</p>
                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2003-2004 йй.  - “Магистрал” йўллардан фойдаланиш бошқармаси, йўл устаси</p>
                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2004-2008 йй.  - “Магистрал” йўллардан фойдаланиш бошқармаси, етакчи мутахассис</p>
                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2008-2009 йй.  - Навоий кўприк хўжалигини сақлашга ихтисослаштирилган таъмирлаш-фойдаланиш корхонаси, етакчи мутахассис</p>
                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2010-2016 йй.  - Навоий кўприк хўжалигини сақлашга ихтисослаштирилган таъмирлаш-фойдаланиш корхонасида кўприк хўжалигини таъмирлаш ва ундан фойдаланиш бўлими бошлиғи</p>
                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2016-2017 йй.  - Навоий кўприк хўжалигини сақлашга ихтисослаштирилган таъмирлаш-фойдаланиш корхонаси директори</p>
                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2017-2022 йй.  - Навоий кўприклардан фойдаланиш унитар корхонаси директори</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2023 -  ҳ.в        - Навоий вилояти Автомобиль йўллари бош бошқармаси бошлиқ ўринбосари</p>




                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle20;
