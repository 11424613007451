import React, {useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Contacts from '../components/Contacts';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {useTranslation} from "react-i18next";
import Footer from "../components/Footer/Footer";
import Branches from "../components/Branches";
import {useLocation} from "react-router-dom";

const Branch = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Branches/>
            <Footer/>
        </div>
    )
}
export default Branch;
