import {API_URL} from "../Constants";
import i18next from "i18next";
import instance from "./api";

class MessageService {

    leaveMessage(message)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/message/`,message,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }
    getall()
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/message/all/`,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }
    getanswered()
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/message/answered/`,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }

    updateMes(m)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/message/update/`,m,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }

    deleteMes(m)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/message/delete/`,m,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }

}
export default new MessageService()
