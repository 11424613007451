import React, {useEffect, useState} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import DesignBanner from '../components/Banner/DesignBanner';
import Service from '../components/Service/Service';
import Subscribe from '../components/Subscribe';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';
import StudySlider from "../components/StudySlider";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import Schedule from "./Event/Schedule";

const Home = () =>{
    const {t} = useTranslation();
    const { pathname } = useLocation();
    const [isSpeakEnabled,setIsSpeakEnabled]=useState(false)
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
    <div className="body_wrapper">
        <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
        <DesignBanner/>

        <Service/>

        <Subscribe translation={{t}} />
        <StudySlider/>
        <Footer FooterData={FooterData}/>
    </div>
)}
export default Home;
