import React, {useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import Teams from '../components/Team/Team';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import TeamRegionList from "../components/Team/TeamRegion";
const TeamRegionMain =()=>{
    const { t, i18n } = useTranslation();

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area breadcrumb_area_three" imgName="breadcrumb/banner_bg.png" Ptitle={t('authority')}/>
            <TeamRegionList/>
            <Footer/>
        </div>
    )
}
export default TeamRegionMain;
