import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle9 = () => {

    const _speech = new SpeechSynthesisUtterance();

    const speak = (event) => {
        if (window.isSpeakingEnabled) {
            _speech.text = event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);
        }
    }
    return (
        <section className="portfolio_details_area sec_pad" style={{maxHeight: "3000px", minHeight: "1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft: "8%"}} className="col-lg-8 ">
                <img style={{height: 250, width: 200, float: 'right'}} className="img-fluid mb_20"
                     src={require('../../img/teams/xatirchiBoshliq.jpg')} alt=""/>
                <div onClick={speak} className="row">
                    <div>
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Фозилов Мунисжон Муродулло ўғли</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2023 йил 19
                                декабрдан:
                                Хатирчи туман йўллардан фойдаланиш давлат муассаси директори


                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2001-2005 йй. – Тошкент автомобил йўллари нститути талабаси</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2005-2006 йй.
                                – Кармана туман халқ таълими бўлими фан услубчиси
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}> 2006-2009
                                йй. – Навоий кон металлургия комбинати ўриндош смена бошлиғи
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}> 2009-2010
                                йй. – Кармана туман йўл хўжалиги пудрат таъмирлаш фойдаланиш давлат корхонаси 217-йўл
                                бўлими бошлиғи в.в.б</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}> 2010-2014
                                йй. - Кармана туман йўл хўжалиги пудрат таъмирлаш фойдаланиш давлат корхонаси ҳаракат
                                ҳавфсизлигини ташкил этиш бўйича муҳандис, бошлиқ ўринбосари, диспетчери, 219-йўл бўлими
                                бошлиғи</p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2014-2015 йй. – Навоий шахар Ободонлаштириш бошқармаси бошлиғи</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2015-2017 йй. – Кармана тумани “AXMED TRANS” маъсулияти чекланган жамияти ижрочи директори</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2017-2017 йй. – Кармана туман Ободонлаштириш бошқармаси бошлиғи</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2017-2017 йй. – Навоий вилояти халқ таълими бошқармаси молиялаштириш ва моддий таъминот бўлими бошлиғи, бошқарма бошлиги уринбосари</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2017-2020 йй. – Навбахор туман йўллардан фойдаланиш унитар корхонаси директори</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2020-2020 йй. – Кармана туман ҳокимининг саноатни ривожлантириш, капитал қурилиш, коммуникациялар ва коммунал хўжалиги масалалари бўйича ўринбосари</p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2022-2023 йй. – “MMM ELEGАNT BUILDER GROUP”маъсулияти чекланган жамият бошқарувчи </p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2023-2023 йй. - “MMM ELEGАNT BUILDER GROUP”маъсулияти чекланган жамият директори</p>

                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2023 й.ҳ.в.       – Хатирчи туман йўллардан фойдаланиш давлат муассаси директори в.в.б

                            </p>



                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle9;
