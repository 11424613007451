import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom'
import {Formik, Form, Field, ErrorMessage} from "formik";
import AuthenticationService from "../api/AuthenticationService";
import ReCaptchaV2 from 'react-google-recaptcha'
import {REACT_APP_SITE_KEY }from '../Constants'
import LoadingScreen from "react-loading-screen";
import loading from "../img/new/loading.png";
import history from "../index";
import BranchService from "../api/BranchService";
import {withTranslation} from "react-i18next";
function gloginError()
{
    this.setState({
        loginError: true
    })
}
function gclearloginError() {
    this.setState({
        loginError: false
    })

}
function gcaptchaError() {
    this.setState({
        captchaError: true
    })
}
function gclearcaptchaError() {
    this.setState({
        captchaError: false
    })
}
function gloadingscreenOff() {
    this.setState({
        loadingscreen:false
    })
}
const Loader = () => (
    <div class="divLoader" style={{display: "flex",justifyContent: "center",alignItems:"center",position:'fixed',
        padding:0,
        margin:0,
        background:"white",
        top:0,
        left:0,
        zIndex:10000000001,
        width: '100%',
        height: '100%'}}>
        <svg class="svgLoader" viewBox="0 0 100 100" width="12em" height="12em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
    </div>
);
class PasswordResetForm extends Component{

    constructor(props) {
        super(props);
        this.state={
            username:'',
            isVerified:false,
            loginError:false,
            captchaError:false,
            loadingscreen:false



        }

        this.validate=this.validate.bind(this)
        gloginError=gloginError.bind(this)
        gclearloginError=gclearloginError.bind(this)
        this.verifyCallback=this.verifyCallback.bind(this)
        this.expiredCallback=this.expiredCallback.bind(this)
        gcaptchaError=gcaptchaError.bind(this)
        gclearcaptchaError=gclearcaptchaError.bind(this)
        this.resetPassword=this.resetPassword.bind(this)
        this.loadingscreeeOff=this.loadingscreeeOff.bind(this)
        this.fsetloading=this.fsetloading.bind(this)
        gloadingscreenOff=gloadingscreenOff.bind(this)
    }
    loadingscreeeOff()
    {
        this.setState({

            loadingscreen:false
        });
    }
    fsetloading()
    {
        this.setState({
            loadingscreen:true
        });
    }
    validate(values)
    {
        let errors={}

        if(!values.username){
            errors.username='Foydalanuvchi nomini yoki emailni kiriting!'
        }

        return errors
    }
    resetPassword(values)
    {
        let user={
        username:values.username
             }

        if(this.state.isVerified)
        {
            this.fsetloading()

            gclearcaptchaError()

            AuthenticationService.resetPassword(user).then(
                (response)=>
            {
                this.loadingscreeeOff()
                gclearloginError()
                history.push("/AfterPasswordResetRequest")
            }).catch(function (error){
                gloadingscreenOff()
                gloginError()
            })


        }

        else{
            gloadingscreenOff()

            gcaptchaError()
        }




    }

    verifyCallback(response)
    {
        if(response)
            this.setState({
                isVerified:true
            })

    }
    expiredCallback()
    {

            this.setState(
                {
                    isVerified:false
                }
            )
        gloadingscreenOff()

    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }


    render() {



    return(
<>
        <section className="sign_in_area bg_color sec_pad">
            {this.state.loadingscreen&&   <Loader/>}
            <div className="container">
                <div className="sign_info">
                    <div className="row">

                        <div className="col-lg-7">
                            <div className="login_info">
                                <h2 onClick={this.speak} className="f_p f_600 f_size_24 t_color3 mb_40">{this.props.t('parolniT')}</h2>

                                <Formik initialValues={{ username: "", email: "",password:"" }}
                                        onSubmit={this.resetPassword}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        validate={this.validate}
                                    // enableReinitialize={true}
                                >{
                                    (props) => (

                                <Form  className="login-form sign-in-form">
                                    <ErrorMessage name='username' component='div'
                                                  className="alert alert-warning"/>

                                    <ErrorMessage name='password' component='div'
                                                  className="alert alert-warning"/>


                                    {this.state.loginError&&<div onClick={this.speak}className="alert alert-warning">{this.props.t('foydalanuvchiNomiX')}</div>}
                                    {this.state.captchaError&&<div onClick={this.speak}className="alert alert-warning">{this.props.t('captchaniT')}</div>}

                                    <div onClick={this.speak}className="form-group text_box">
                                        <label className="f_p text_c f_400">{this.props.t('e-mailYokiFN')}</label>
                                        <Field type="text" name="username" placeholder="foydalanuvchi@gmail.com"/>
                                    </div>
                                    <div onClick={this.speak}className="form-group text_box">
                                        <label className="f_p text_c f_400">CAPTCHA</label>
                                        <ReCaptchaV2 sitekey={REACT_APP_SITE_KEY}
                                                     onChange ={this.verifyCallback}
                                                     onExpired={this.expiredCallback}
                                        />
                                    </div>
                                    <div className="extra mb_20">



                                    </div>
                                    <div onClick={this.speak} className="d-flex justify-content-between align-items-center">
                                        <button type="submit" className="btn_three">OK</button>
                                        {/*<div className="social_text d-flex ">*/}
                                        {/*    <div className="lead-text">Don't have an account?</div>*/}
                                        {/*    <ul className="list-unstyled social_tag mb-0">*/}
                                        {/*        <li><a href="/#"><i className="ti-facebook"></i></a></li>*/}
                                        {/*        <li><a href="/#"><i className="ti-twitter-alt"></i></a></li>*/}
                                        {/*        <li><a href="/#"><i className="ti-google"></i></a></li>*/}
                                        {/*    </ul>*/}
                                        {/*</div>*/}
                                    </div>
                                </Form>)}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}}
export default withTranslation()(PasswordResetForm);
