import React, {Component} from 'react';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
import { Link } from "react-router-dom";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import Pdf from '../../../Documents/UZ_2021_03_ustavi.pdf';
import MessageService from "../../../api/MessageService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import history from "../../../index";
import Popup from "reactjs-popup";
import DOMPurify from "dompurify";

import {
    Button, Card, CardBody, CardFooter, CardHeader,
    Col,
    Container,
    Form,
    FormGroup, Input, Label,
    Row,
    Table,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Blogrightsidebar from "../../Blog/Blogrightsidebar";
import {withTranslation} from "react-i18next";
import Blogrightsidebar3 from "../../Blog/Blogrightsidebar3";
import Blogrightsidebar4 from "../../Blog/Blogrightsidebar4";
import DocFilesService from "../../../api/DocFilesService";
import axios from "axios";
import {API_URL} from "../../../Constants";
import FileDownload from "js-file-download";
import CKEditor from 'ckeditor4-react';
class AnsweredMessagesService extends Component{


    constructor() {
        super();
        this.state={
            messages:[]
        }
    }

    componentDidMount() {

        MessageService.getanswered().then((res)=>{
            this.setState({
                messages:res.data
            })
        })

    }
    editMes=(m)=>{
        if(m.isAnswered)
            toast.warning(this.props.t("cannotEditAnswered"))
        else{

        }

    }
    deleteMes=(m)=>{
        toast.configure()

        if(m.answered) {
            toast.warning(this.props.t("cannotDeleteAnswered"))
        }
        else{
            MessageService.deleteMes(m).then((res)=>{
                toast.success(this.props.t('messageDeleteSuccess'))
                setTimeout(function () {
                    history.push("/settings")
                },2000)
            })
        }
    }

    updateMes=(event,m)=>{
        event.preventDefault()
        m.question=this.state.question
        MessageService.updateMes(m).then((res)=>{
            toast.success(this.props.t('messageEditSuccsess'))
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }


    render(){
        return(
            <section className="agency_service_area bg_color" style={{marginBottom:"10px"}}>
                <div>
                <div style={{float:"left",width:"20%",marginRight:"5%"}}>
                    <Blogrightsidebar4 />
                </div>
                <div style={{float:"right",width:"70%",marginRight:"5%",marginTop:"70px"}} >

                    <div className="table-responsive bg-white shadow rounded mt-4">
                        <Table className="mb-0 table-center">
                            <thead className="bg-light">
                            <tr>

                                <th onClick={this.speak}scope="col" style={{ minWidth: "300px" }}>
                                    {this.props.t('xabar')}
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ maxWidth: "150px" }}
                                    onClick={this.speak}
                                >
                                    {this.props.t('javob')}
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ maxWidth: "150px" }}

                                >
                                    {this.props.t('sanaJavob')}
                                </th>


                            </tr>
                            </thead>
                            <tbody>
                            {this.state.messages.map((f,k)=><tr key={k}>



                                        <td onClick={this.speak}
                                                  className="text-center small h6"><article onClick={this.speak} className="f_400 mb-30 bold" style={{color:'black'}}
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: DOMPurify.sanitize(  f.question)
                                                                                            }}
                                            /></td>






                                <td onClick={this.speak}
                                    className="text-center small h6"><article onClick={this.speak} className="f_400 mb-30 bold" style={{color:'black'}}
                                                                              dangerouslySetInnerHTML={{
                                                                                  __html: DOMPurify.sanitize( f.answer)
                                                                              }}
                                /></td>

                                <td onClick={this.speak}
                                    className="text-center small h6">{(new Date(f.updatedAt).toLocaleDateString())}{" "}{(new Date(f.updatedAt).toLocaleTimeString())}</td>


                            </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(AnsweredMessagesService);
