import React from 'react';
import axios from 'axios';
import {API_URL} from "./Constants";
import history from "./index";
const Loader = () => (
    <div class="divLoader" style={{display: "flex",justifyContent: "center",height: "100vh",alignItems:"center"}}>
        <svg class="svgLoader" viewBox="0 0 100 100" width="12em" height="12em">
            <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
    </div>
);

class Content extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
        }
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const foo = params.get('token');

        axios.get(`${API_URL}/api/auth/register/verify?token=`+foo)
            .then((res) => {
                history.push("/success")
            })
            .catch((error)=>{
               history.push("/error")
            })
    }


    render() {
        return (

            <div>
                {this.state.loading ? <Loader  /> : null}
            </div>

        );
    }
}

export default Content;