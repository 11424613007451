import React, {Component} from 'react';
import Slider from 'react-slick/';
import BranchService from "../api/BranchService";
import i18next from "i18next";
import {API_URL} from "../Constants";
import {Link,useLocation} from "react-router-dom";

class StudySlider extends Component {

    constructor() {
        super();
        this.state={
            branches:[]
        }
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {
        BranchService.getAll().then((response)=>
        {
            this.setState({
                branches:response.data
            })
        })
        this._speech = new SpeechSynthesisUtterance();
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
          };
        return (
            <React.Fragment>
                <Slider {...settings} className="case_studies_slider">
                    {this.state.branches.map((b,key)=>
                    <div onClick={this.speak}className="iitem" key={key}>
                        <div className="studies_item">

                            <Link to={{
                                pathname: '/Branch',
                                id: b.id
                            }}
                               >
                            <img style={{width:"487px",height:"365px"}} src={`${API_URL}/api/files/${b.image}`} alt=""/>
                            <div className="text">
                                    <h4 onClick={this.speak}>{i18next.language==='uz'&&b.name_uz}</h4>
                                    <h4 onClick={this.speak}>{i18next.language==='ru'&&b.name_ru}</h4>
                                    <h4 onClick={this.speak}>{i18next.language==='kirill'&&b.name_kirill}</h4>

                            </div>
                            </Link>
                        </div>
                    </div>
                    )}
                </Slider>

            </React.Fragment>
        )
    }
}

export default StudySlider;
