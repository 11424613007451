
import {API_URL} from "../Constants";
import instance from "./api";

class CommentService {

    makeComment(comment)
    {  let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/comments/`,comment,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }
    getByNews(newsId,page,size)
    {  let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/comments/${newsId}/?page=${page}&size=${size}`,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }
}
export default new CommentService()
