import React, {Component} from 'react';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
import { Link } from "react-router-dom";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import Pdf from '../../../Documents/UZ_2021_03_ustavi.pdf';
import MessageService from "../../../api/MessageService";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import history from "../../../index";
import Popup from "reactjs-popup";


import {
    Button, Card, CardBody, CardFooter, CardHeader,
    Col,
    Container,
    Form,
    FormGroup, Input, Label,
    Row,
    Table,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import Blogrightsidebar from "../../Blog/Blogrightsidebar";
import {withTranslation} from "react-i18next";
import Blogrightsidebar3 from "../../Blog/Blogrightsidebar3";
import Blogrightsidebar4 from "../../Blog/Blogrightsidebar4";
import DocFilesService from "../../../api/DocFilesService";
import axios from "axios";
import {API_URL} from "../../../Constants";
import FileDownload from "js-file-download";
import CKEditor from 'ckeditor4-react';
class AllLeftMessagesService extends Component{


    constructor() {
        super();
        this.state={
            messages:[],
            question:''
        }
    }

    componentDidMount() {

        MessageService.getall().then((res)=>{
            this.setState({
                messages:res.data
            })

        })

    }
    editMes=(m)=>{
        if(m.isAnswered)
            toast.warning(this.props.t("cannotEditAnswered"))
        else{

        }

    }
    deleteMes=(m)=>{
        toast.configure()

        if(m.answered) {
            toast.warning(this.props.t("cannotDeleteAnswered"))
        }
        else{
            MessageService.deleteMes(m).then((res)=>{
                toast.success(this.props.t('messageDeleteSuccess'))
                setTimeout(function () {
                    history.push("/usersettings")
                },2000)
            })
        }
    }

    updateMes=(event,m)=>{
        toast.configure()
        event.preventDefault()
        m.question=this.state.question
        MessageService.updateMes(m).then((res)=>{
            toast.success(this.props.t('messageEditSuccsess'))
            setTimeout(function () {
                window.location.reload()
            },2000)
        })
    }


    render(){
        return(
            <section className="agency_service_area bg_color" style={{marginBottom:"10px"}}>
                <div>
                <div style={{float:"left",width:"20%",marginRight:"5%"}}>
                    <Blogrightsidebar4 />
                </div>
                <div style={{float:"right",width:"70%",marginRight:"5%",marginTop:"70px"}} >

                    <div className="table-responsive bg-white shadow rounded mt-4">
                        <Table className="mb-0 table-center">
                            <thead className="bg-light">
                            <tr>
                                <th onClick={this.speak}scope="col" style={{ minWidth: "300px" }}>
                                    ID
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ maxWidth: "150px" }}
                                    onClick={this.speak}
                                >
                                    {this.props.t('sana')}
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ maxWidth: "150px" }}

                                >
                                </th>
                                <th
                                    scope="col"
                                    className="text-center"
                                    style={{ maxWidth: "150px" }}
                                >
                                </th>

                            </tr>
                            </thead>
                            <tbody>
                            {this.state.messages.map((f,k)=><tr key={k}>
                                <td>
                                    <div className="media">
                                        <FeatherIcon  className="fea icon-ex-md"/>
                                        <div className="content ml-3" style={{fontSize: "14px"}}>
                                            <Link
                                                to="#"
                                                className="forum-title text-primary font-weight-bold"
                                                onClick={this.speak}
                                            >
                                                {f.id}
                                            </Link>
                                            <p className="text-muted small mb-0 mt-2">

                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td onClick={this.speak}
                                    className="text-center small h6">{(new Date(f.updatedAt).toLocaleDateString())}{" "}{(new Date(f.updatedAt).toLocaleTimeString())}</td>

                                {f.answered&&<td onClick={this.speak}
                                                 className="text-center small h6"> {this.props.t('cannotDoAny')}</td>}
                                <td onClick={this.speak}className="text-center small">
                                    {!f.answered&&<Popup trigger={<Button style={{fontSize: "100%", height: "100%"}} color="primary"
                                                                          className="btn small btn-primary">{this.props.t('ozgartirish')}</Button>}
                                                         position="right center" modal nested>
                                        <Container fluid={true}>
                                            <Card style={{
                                                maxHeight: 'calc(100vh - 210px)',
                                                overflowY: 'auto',
                                                width: "600px"

                                            }}>
                                                <Col xl="16">
                                                    <Form className="card">
                                                        <CardHeader>
                                                            <div className="card-options">
                                                                <a className="card-options-collapse" href="#javascript">
                                                                    <i className="fe fe-chevron-up"></i>
                                                                </a>
                                                                <a className="card-options-remove" href="#javascript">
                                                                    <i className="fe fe-x"></i>
                                                                </a>
                                                            </div>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    <FormGroup>

                                                                        <CKEditor
                                                                            data={f.question}
                                                                            onChange={evt => this.setState({question: evt.editor.getData()})}
                                                                        />

                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                        <CardFooter className="text-right">
                                                            <Button className="btn btn-primary"
                                                                    onClick={(event) => this.updateMes(event,f)}>{this.props.t('yangilash')}</Button>
                                                        </CardFooter>
                                                    </Form>
                                                </Col>
                                            </Card>
                                        </Container>
                                    </Popup>}

                                </td>
                                {!f.answered&&<td onClick={this.speak} className="text-center small">
                                    <button className="btn small  btn-danger"
                                            style={{fontSize: "10px"}}
                                            onClick={() => this.deleteMes(f)}>{this.props.t('ochirish')}</button>
                                </td>}
                            </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(AllLeftMessagesService);
