import React, {Component} from 'react';

class Subscribe extends Component {

    constructor(props) {
        super(props);
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }
    render(){
        const{t}=this.props.translation
        return(
            <React.Fragment>
                 <section className="seo_partner_logo_area sec_pad">
                    <div className="container">
                        <div className="seo_sec_title text-center mb_70">
                           <h2  onClick={this.speak}>{t('havola')}</h2>
                        </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="seo_service_item">
                                        <div className="owl-item active" style={{width: "255px", marginRight: "30px"}}>
                                            <div><a href="https://www.minjust.uz/ru/" target="_blank" className="partner">
                                                <div className="partner__logo">
                                                    <img src={require('../img/natLogo1.png')} width="274" height="304" alt=""
                                                         loading="lazy" typeof="foaf:Image"/>                                                </div>
                                                <span  onClick={this.speak} className="partner__title">
                                                    {t('adliyaVazirligi')}
                                                </span>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="seo_service_item">
                                        <div className="owl-item active" style={{width: "255px", marginRight: "30px"}}>
                                            <div><a href="https://president.uz/uz" target="_blank" className="partner">
                                                <div className="partner__logo">
                                                    <img src={require('../img/logoNat3.png')} width="274" height="304" alt=""
                                                         loading="lazy" typeof="foaf:Image"/>                                                </div>
                                                <span  onClick={this.speak}className="partner__title">
                                                    {t('prezidentVebS')}
                                                </span>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="seo_service_item">
                                        <div className="owl-item active" style={{width: "255px", marginRight: "30px"}}>
                                            <div><a href="https://www.gov.uz/ru" target="_blank" className="partner">
                                                <div className="partner__logo">
                                                    <img src={require('../img/logoNat2.png')} width="274" height="304" alt=""
                                                         loading="lazy" typeof="foaf:Image"/>                                                </div>
                                                <span  onClick={this.speak}className="partner__title">
                                                    {t('hukumatPortali')}
                                                </span>
                                            </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>

                </section>

            </React.Fragment>
        )
    }
}


export default Subscribe
