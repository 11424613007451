import React, {Component} from 'react';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
import Blogrightsidebar from "../../Blog/Blogrightsidebar";
import Fade from "react-reveal/Fade";
import {useTranslation} from "react-i18next";
import video from "../../Video";
import {API_URL} from "../../../Constants";
import Pagination from 'rc-pagination';
import 'rc-pagination/assets/index.css'
import GalleryVideoService from '../../../api/GalleryVideoService'
import i18next from "i18next";
import BranchService from "../../../api/BranchService";

class VideoGalleryPart extends Component{


    constructor(props) {
        super(props);
        this.state={
            videos:[],
            current:1,
            total:0,
            language:''
        }
    }
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }

    componentDidMount() {
        GalleryVideoService.getAllVideos().then((response)=>{
            console.log(response.data.length)
            this.setState({
                total:response.data.length
            })
        })
      GalleryVideoService.getVideoPage(this.state.current-1,4).then((response)=> {

              this.setState({
                  videos: response.data,
                  language: i18next.language,

              })
          }
      )
        this._speech = new SpeechSynthesisUtterance();

    }

     onChange=(x)=>{

         GalleryVideoService.getVideoPage(x-1,5).then((response)=>{
             this.setState({
                 videos:response.data,
                 current:x,
                 language:i18next.language
             })
         })
    }

    render(){
        const{t}=this.props.translation
        return(
            <section className="agency_service_area bg_color" style={{marginBottom:"10px"}}>
                <div>
                <div style={{float:"left",width:"20%",marginRight:"5%"}}>
                    <Blogrightsidebar/>
                </div>
                <div style={{float:"right",width:"70%",marginRight:"5%"}}>
                    <div>
                        <section className="seo_features_one sec_pad">
                            <div className="container">
                                    <div>
                                        {this.state.videos.map((v,i)=>
                                            <div key={i} className="column">
                                    <div className="col-lg-6" style={{float:"left",width:"60%",marginRight:"5%"}}>
                                        <div className="seo_features_img seo_features_img_two">
                                            <div className="round_circle"></div>
                                            <div className="round_circle two"></div>
                                            {/*<img style={{height:"450px",width:"600px"}} src={require('../../img/seo/features_img_two.png')} alt=""/>*/}
                                            <video src={`${API_URL}/api/files/${v.minioVideoId}`} style={{maxHeight:"400px",width:"550px"}} controls="controls" autoPlay={false}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 d-flex align-items-center" style={{float:"right",width:"40%",marginRight:"5%"}}>
                                        <Fade bottom cascade>
                                            <div className="seo_features_content">
                                                {this.state.language==='uz'&&<h2 onClick={this.speak} className="wow fadeInUp">{v.title_uz}</h2>}
                                                {this.state.language==='ru'&&<h2 onClick={this.speak}className="wow fadeInUp">{v.title_ru}</h2>}
                                                {this.state.language==='kirill'&&<h2 onClick={this.speak}className="wow fadeInUp">{v.title_kirill}</h2>}
                                                {this.state.language==='uz'&&<h6 onClick={this.speak}className="wow fadeInUp">{v.description_uz}</h6>}
                                                {this.state.language==='ru'&&<h6 onClick={this.speak}className="wow fadeInUp">{v.description_ru}</h6>}
                                                {this.state.language==='kirill'&&<h6 onClick={this.speak}className="wow fadeInUp">{v.description_kirill}</h6>}
                                                {/*<p className="wow fadeInUp">Bu yerga ma'lumot qo'yiladi 04</p>*/}
                                                {/*<a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Ko'proq bilish</a>*/}
                                            </div>
                                        </Fade>
                                    </div>
                                                </div>)}
                                        <div className="col-lg-6" style={{float:"left",width:"60%",marginRight:"5%",marginTop:"100px",zIndex: 11111}}>  <Pagination
                                            onChange={(x)=>this.onChange(x)}
                                            current={this.state.current}
                                            total={this.state.total}
                                            pageSize={5}


                                        /></div>


                                    </div>

                            </div>
                        </section>
                    </div>
                </div>
                </div>

            </section>
        )
    }
}
export default ()=>{
    const {t} = useTranslation();
    return (
        <VideoGalleryPart translation={{t}} />
    )
};
