import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Card,
  CardBody,
  Media, CardHeader, Collapse, Table, Input,
} from "reactstrap";
import classnames from "classnames";
import SectionTitle from "../../components/Shared/SectionTitle";
import {withTranslation} from "react-i18next";
import AllRoadsForBranchService from "../../api/AllRoadsForBranchService";
import SeoTitle from "../../components/Title/SeoTitle";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {

      roads:[],
      items:[
          {
        price:'1255'
      }
      ],
      activeTab: "1",
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      pathItems: [
        //id must required
        { id: 1, name: "Landrick", link: "/index" },
        { id: 2, name: "Shop", link: "#" },
        { id: 3, name: "Cart" },
      ],

    };
    this.toggle = this.toggle.bind(this);
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
  }

  toggle(tab) {
    this.setState({ activeTab: tab });
  }
  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
    });
  }
  componentDidMount() {
    AllRoadsForBranchService.getAll().then((response)=>{
      this.setState({
        roads:response.data
      })
    })
  }

  render() {
    return (
      <React.Fragment>
        <section className="section">
          <div style={{maxWidth:"1450px",margin:"auto"}}>
            {/* section title */}
            <SeoTitle
              Title={this.props.t('schedule2')}
            />

            <Row>
              <Col xs="12">
                <div className="tab-content" id="pills-tabContent">
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      {this.state.roads.map((r,k)=><div  key={k}>
                        <Card className="border-0 rounded mb-2">
                          <Link
                              to="#"
                              onClick={this.t_col1}
                              className={
                                this.state.col1
                                    ? "faq position-relative text-primary"
                                    : "faq position-relative text-dark"
                              }
                          >
                            <CardHeader
                                className="border-0 shadow bg-light p-3"
                                id="headingOne"
                            >
                              <h6 className="title mb-0" style={{fontSize:"20px"}}>
                                {" "}
                                {r.name}
                                <i
                                    className={
                                      this.state.col1
                                          ? "mdi mdi-chevron-up float-right"
                                          : "mdi mdi-chevron-down float-right"
                                    }
                                ></i>
                              </h6>
                            </CardHeader>
                          </Link>
                          <Collapse isOpen={this.state.col1}>
                            <CardBody>
                              <Row>
                                <Col xs={12}>
                                  <div>
                                    <Table>
                                      <thead style={{fontSize: "16px"}}>
                                      <tr style={{textAlign: "center"}}>
                                        <th style={{padding: "0.41rem"}}>
                                          {this.props.t('yolU')}
                                        </th>
                                        <th style={{padding: "0.41rem"}}>
                                          {this.props.t('ahamiyatiB')}

                                          <tr style={{
                                            width: "100%",
                                            display: "flex",
                                            float: "left",
                                            textAlign: "center"
                                          }}>
                                            <th style={{textAlign: "center", width: "33%", padding: "0.41rem"}}>{this.props.t('xalqaro')}</th>
                                            <th style={{textAlign: "center", width: "33%", padding: "0.41rem"}}>{this.props.t('davlatA')}</th>
                                            <th style={{textAlign: "center", width: "33%", padding: "0.41rem"}}>{this.props.t('mahalliyA')}</th>

                                          </tr>

                                        </th>
                                        <th style={{padding: "0.41rem"}}>
                                          {this.props.t('qoplamaT')}

                                          <tr style={{
                                            width: "100%",
                                            display: "flex",
                                            float: "left",
                                            textAlign: "center"
                                          }}>
                                            <th style={{
                                              textAlign: "center",
                                              width: "20%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('sB')}</th>
                                            <th style={{
                                              textAlign: "center",
                                              width: "20%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('aB')}</th>
                                            <th style={{
                                              textAlign: "center",
                                              width: "20%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('qora')}</th>
                                            <th style={{
                                              textAlign: "center",
                                              width: "20%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('shagal')}</th>
                                            <th style={{
                                              textAlign: "center",
                                              width: "20%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('tuproq')}</th>
                                          </tr>

                                        </th>

                                        <th style={{padding: "0.41rem"}}>
                                          {this.props.t('kopriklar')}

                                          <tr style={{
                                            width: "100%",
                                            display: "flex",
                                            float: "left",
                                            textAlign: "center"
                                          }}>
                                            <th style={{
                                              textAlign: "center",
                                              width: "50%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('dona')}

                                            </th>
                                            <th style={{
                                              textAlign: "center",
                                              width: "50%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('pM')}

                                            </th>
                                              </tr>
                                            </th>


                                        <th style={{padding: "0.41rem"}}>
                                          {this.props.t('quvurlar')}

                                          <tr style={{
                                            width: "100%",
                                            display: "flex",
                                            float: "left",
                                            textAlign: "center"
                                          }}>
                                            <th style={{
                                              textAlign: "center",
                                              width: "50%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('dona')}

                                            </th>
                                            <th style={{
                                              textAlign: "center",
                                              width: "50%",
                                              padding: "0.41rem"
                                            }}>{this.props.t('pM')}

                                            </th>
                                          </tr>
                                        </th>

                                      </tr>
                                      </thead>

                                      <tbody style={{fontSize: "15px"}}>
                                          <tr key={k} style={{textAlign: "center"}}>
                                            <td style={{padding: "0.41rem"}}>
                                              <tr style={{width: "100%", float: "left", display: "flex"}}>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "100%",
                                                  padding: "0.41rem"
                                                }}> {r.allRoadLength}</td>
                                              </tr>

                                            </td>
                                            <td style={{padding: "0.41rem"}}>
                                              <tr style={{width: "100%", float: "left", display: "flex"}}>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "33%",
                                                  padding: "0.41rem"
                                                }}> {r.international}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "33%",
                                                  padding: "0.41rem"
                                                }}> {r.state}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "33%",
                                                  padding: "0.41rem"
                                                }}> {r.local}</td>


                                              </tr>

                                            </td>
                                            <td style={{padding: "0.41rem"}}>
                                              <tr style={{width: "100%", float: "left", display: "flex"}}>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "20%",
                                                  padding: "0.41rem"
                                                }}> {r.sb}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "20%",
                                                  padding: "0.41rem"
                                                }}> {r.ab}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "20%",
                                                  padding: "0.41rem"
                                                }}> {r.qq}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "20%",
                                                  padding: "0.41rem"
                                                }}> {r.sh}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "20%",
                                                  padding: "0.41rem"
                                                }}> {r.t}</td>


                                              </tr>

                                            </td>

                                            <td style={{padding: "0.41rem"}}>
                                              <tr style={{width: "100%", float: "left", display: "flex"}}>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "50%",
                                                  padding: "0.41rem"
                                                }}> {r.bridgePiece}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "50%",
                                                  padding: "0.41rem"
                                                }}> {r.bridgePm}</td>




                                              </tr>

                                            </td>
                                            <td style={{padding: "0.41rem"}}>
                                              <tr style={{width: "100%", float: "left", display: "flex"}}>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "50%",
                                                  padding: "0.41rem"
                                                }}> {r.pipePiece}</td>
                                                <td style={{
                                                  textAlign: "center",
                                                  width: "50%",
                                                  padding: "0.41rem"
                                                }}> {r.pipePm}</td>



                                              </tr>

                                            </td>
                                          </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>)}
                    </TabPane>

                  </TabContent>
                </div>
              </Col>

            </Row>

          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Schedule);
