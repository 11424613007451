import {Map, Placemark, YMaps} from "react-yandex-maps";
import React, {Component} from "react";

const mapData = {
    center: [40.078187, 65.407124],
    zoom: 15,
};

const coordinates = [
    [40.078187, 65.407124]

];
export class Geo2 extends Component
{render(){
    return(

        <YMaps>
            <Map defaultState={mapData}>
                {coordinates.map(coordinate =>

                    <Placemark geometry={coordinate} />)}
            </Map>
        </YMaps>

    );}}
