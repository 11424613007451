import axios from "axios";
import {API_URL} from "../Constants";


class NewsService {
    getAllNews()
    {
        return axios.get(`${API_URL}/api/news/`)
    }
    getNewsById(id,config)
    {
        return axios.get(`${API_URL}/api/news/${id}`,config)
    }


}
export default new NewsService()
