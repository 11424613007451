import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import Portfolio2griditem from './Portfolio2griditem';
import GalleryPictureService from "../../api/GalleryPictureService";

class Portfoliofull4col extends Component{
    constructor() {
        super();
        this.state={
            galleryPictures:[]
        }
    }

    componentDidMount() {

        GalleryPictureService.getAllFatherGalleryPictures().then(response=>{
            this.setState({galleryPictures:response.data})
        })

    }


    render(){
        return(
            <section className="portfolio_fullwidth_area bg_color">
                <div className="container-fluid pl-0 pr-0">

                    <div className="row portfolio_gallery ml-0 mr-0" id="work-portfolio">
                        {this.state.galleryPictures.map(
                            (p,i)=>
                                <Portfolio2griditem colClass="col-lg-3 mb-0 p0 col-sm-6" pImg={p.minioImageId} ptitle={p.description}/>


                        )}
                        </div>
                </div>
            </section>
        )
    }
}
export default Portfoliofull4col;
