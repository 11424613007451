
import {API_URL} from '../Constants'
import history from "../index";
import instance from "./api";
import {createBrowserHistory} from "history";
export const USER_NAME_SESSION_ATTRIBUTE_NAME='authenticatedUser'
const historyForce=createBrowserHistory({
    forceRefresh: true
});
class AuthenticationService {

    checkIfUsernameExists(user)
    {
        return instance.post(`${API_URL}/api/authenticate/checkUsername`,user)
    }

    checkIfEmailExists(user)
    {
        return instance.post(`${API_URL}/api/authenticate/checkEmail`,user)
    }
    registerNewUser(user){
        return instance.post(`${API_URL}/api/authenticate`,user)
    }
    signup(user)
    {
        return instance.post(`${API_URL}/api/auth/signup`,user)
    }
    createJwtToken(token)
    {
        return 'Bearer '+token
    }
    getLoggedInUserName(){
        let sessionUser =sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        let localUser =localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if(sessionUser===null&&localUser===null)   return ''
        else if(sessionUser===null&&localUser!==null)
            return localUser
        else
            return sessionUser
    }
    logout(){
        sessionStorage.clear()
        localStorage.clear()
    }
    signin(user)
    {
        return instance.post(`${API_URL}/api/auth/signin`, user)
    }
    isUserLoggedIn(){
        let sessionUser =sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        let localUser=localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if(sessionUser===null&&localUser===null)
            return false
        else
        return true
    }
    sessionregisterSuccessfulLoginForJwt(username,token)
    {
        sessionStorage.clear()
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
        this.setupAxiosInterceptors(this.createJwtToken(token))
        sessionStorage.setItem('token',this.createJwtToken(token));
        historyForce.push("/")
    }
    localregisterSuccessfulLoginForJwt(username,token)
    {
        localStorage.clear()
        localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username);
        this.setupAxiosInterceptors(this.createJwtToken(token))
        localStorage.setItem('token',this.createJwtToken(token));
        historyForce.push("/")
    }
    setupAxiosInterceptors(token)
    {
        instance.interceptors.request.use(
            (config)=>{
                if(this.isUserLoggedIn()){
                    config.headers.authorization=token
                }
                return config
            }
        )

    }
    updateUser(user)
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.post(`${API_URL}/api/user/update/`,user,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }
    resetPassword(user)
    {
        return instance.post(`${API_URL}/api/auth/resetpassword`,user)
    }

    getUserDetails()
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/user/details/`,{
            headers:{
                'Authorization':token
            },
            withCredentials:true
        })
    }

    resetPasswordFinal(resetpasswordform)
    {
        return instance.post(`${API_URL}/api/auth/resetpassword/final`,resetpasswordform)
    }
    check()
    {
        let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/auth/check/`,{
            withCredentials:true,
            headers:{
                'Authorization':token
            }
        })
    }
    check22()
    { let token;
        if(sessionStorage.getItem('token')===null||sessionStorage.getItem('token')==='')
            token=sessionStorage.getItem('token')
        else
            token=sessionStorage.getItem('token')
        return instance.get(`${API_URL}/api/auth/check2/`,{
            withCredentials:true,
            headers:{
                'Authorization':token
            }
        })
    }

}
export default new AuthenticationService()
