import React, {Component} from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {useTranslation, withTranslation} from "react-i18next";
import Footer from "../components/Footer/Footer";
import SearchIcon from "@material-ui/icons/Search";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import history from "../index";
class Search extends Component{
constructor() {
    super();
    this.state={
        searchFor:''
    }
}
    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }
    onChange=(event)=>{
        this.setState({
            searchFor:event.target.value
        })
    }
    checkNotEmptyKey2=(event)=>{

        if (event.charCode === 13) {
            toast.configure()
            if(this.state.searchFor==='')
                toast.warning(this.props.t("kalitSK"))
            else if(this.state.searchFor!=='')
                history.push({
                    pathname: '/searchresults',
                    state: this.state.searchFor
                })}
    }
    render() {
        return(
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          {/*<img src={require("../img/new/error.png")} alt="" />*/}
          <h2 onClick={this.speak}>{this.props.t('saytBQ')}</h2>

          <form action="#" className="search">
            <input type="text" style={{color:"black"}} onKeyPress={(event)=>this.checkNotEmptyKey2(event)} className="form-control" placeholder={this.props.t('kalitSK')} onChange={this.onChange} />
          </form>
          <Link  to={{
              pathname: "/searchresults",
              state: this.state.searchFor,

          }} className="about_btn btn_hover">
              <SearchIcon fontSize={'large'}/>
              {this.props.t('qidiruv')} <i className="arrow_right"></i>
          </Link>
        </div>
      </div>
    </section>
    <Footer FooterData={FooterData} />
  </div>
);}}
export default withTranslation()(Search);
