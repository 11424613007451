import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle21 =()=>{

    const _speech = new SpeechSynthesisUtterance();

    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }
    return(
        <section className="portfolio_details_area sec_pad" style={{maxHeight:"3000px",minHeight:"1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%"}} className="col-lg-8 " >
                <img  style={{ height: 250,width:200,float:'right'}}className="img-fluid mb_20" src={require('../../img/teams/kotib.jpg')} alt=""/>
                <div onClick={speak} className="row" >
                    <div  >
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Баҳромов Дилшоджон Улуғбек ўғли</h5>
                            <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} >2023 йил августдан:
                                Навоий вилояти автомобил йўллари бош бошқармаси бошлиғининг матбуот котиби, ахборот сиёсати масалалари бўйича маслахатчиси


                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>
                                2015-2016 йй. - Навбаҳор тумани “Дилшод Баракат Навоий” МЧЖ директори</p>
                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2016-2016 йй. - Навоий вилоят тарихий-ўлкашунослик музейи фотосуратчиси 0,5 ставка</p>
                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2016-2017  йй. - Навоий вилояти тарихий - ўлкашунослик музей назоратчиси</p>
                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2017-2017 йй. - O`zLiDeP Навоий вилоят ҳудудий партия ташкилоти Ёшлар билан ишлаш бўлими етакчи консультанти</p>
                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>    2017-2018 йй. - Жиззах политехника институти талабаси</p>
                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>  2018-2023 йй.   - Бухоро муҳандислик технология институти талабаси</p>
                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>   2019-2020 йй   – Навбахор ТЙФУК матбуот котиби, ахборот сиёсати масалалари бўйича директор маслахатчиси</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>   2020–2023 йй   Навбахор ТЙФУК  ёшлар сиёсати,  ижтимоий ривожлантириш  ва  маънавий-маърифий  ишлар бўйича директор ўринбосари</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>   2023 й – х.в   Навоий вилояти автомобил йўллари бош бошқармаси бошлиғининг матбуот котиби, ахборот сиёсати масалалари бўйича маслахатчиси

                            </p>



                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle21;
