import CustomNavbar from "../../components/CustomNavbar";
import DesignBanner from "../../components/Banner/DesignBanner";
import Service from "../../components/Service/Service";
import Subscribe from "../../components/Subscribe";
import FooterData from "../../components/Footer/FooterData";
import StudySlider from "../../components/StudySlider";
import Footer from "../../components/Footer/Footer";
import React, {useEffect} from "react";
import News from "../News/News"
import {NewsList} from "./News2";
import ReactPaginate from 'react-paginate';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
const NewsFullPage = () =>{
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="body_wrapper">
            <CustomNavbar cClass="custom_container p0" hbtnClass="new_btn"/>
            <NewsList url={'http://localhost:8080/api/news/'} author={'NAYBB'} perPage={7} translation={{t}} />
            <Footer FooterData={FooterData}/>
        </div>
    )}
export default NewsFullPage;
