import React, {Component} from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {useTranslation, withTranslation} from "react-i18next";
import Footer from "../components/Footer/Footer";
import SearchIcon from "@material-ui/icons/Search";
import {Link} from "react-router-dom";
import ScheduleTab from "../components/scheduleTab";
class SearchResults extends Component{

    speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            this._speech.text =event.currentTarget.textContent;
            this._speech.lang = 'ru_RU';
            window.speechSynthesis.speak(this._speech);}
    }
    componentDidMount() {

        this._speech = new SpeechSynthesisUtterance();
    }
    render() {
        return(
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn"/>
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          {/*<img src={require("../img/new/error.png")} alt="" />*/}
          <h2 onClick={this.speak}>{this.props.t('qidiruvN')}</h2>
            <ScheduleTab/>
        </div>

      </div>
    </section>
    <Footer FooterData={FooterData} />
  </div>
);}}
export default withTranslation()(SearchResults);
