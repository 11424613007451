import axios from "axios";
import {API_URL} from "../Constants";


class GalleryPictureService {

    getAllGalleryPictures()
    {
        return axios.get(`${API_URL}/api/gallery/`)
    }

    getAllFatherGalleryPictures()
    {
        return axios.get(`${API_URL}/api/gallery/father`)
    }

    getAllFamilyGalleryPictures(id)
    {
        return axios.get(`${API_URL}/api/gallery/family/${id}`)
    }

}
export default new GalleryPictureService()
