import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle14 = () => {

    const _speech = new SpeechSynthesisUtterance();

    const speak = (event) => {
        if (window.isSpeakingEnabled) {
            _speech.text = event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);
        }
    }
    return (
        <section className="portfolio_details_area sec_pad" style={{maxHeight: "3000px", minHeight: "1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft: "8%"}} className="col-lg-8 ">
                <img style={{height: 250, width: 200, float: 'right'}} className="img-fluid mb_20"
                     src={require('../../img/teams/nurotaBoshliq.jpg')} alt=""/>
                <div onClick={speak} className="row">
                    <div>
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Norboboyev Ravshan Yodgorovich</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2017 yil 18 avgustdan:
                                Nurota tumani yullardan foydalanish davlat muassasasi direktori



                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">MEHNAT FAOLIYATI</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                1987-1989 yy. - Harbiy xizmatda</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                1989-1993 yy. - Toshkent avtomobil yo‘llari instituti talabasi</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>1993-1994 yy. - Nurota tumani avtomobil yo‘llari boshqarmasi 226-yo‘l bo‘limi yo‘l ishchisi
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>1994-1995 yy. - Nurota tumani avtomobil yo‘llari boshqarmasi 227-yo‘l bo‘limi hisobchi
                                muhandisi

                            </p>


                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                1995-2000 yy. - Nurota tumani avtomobil yo‘llari boshqarmasi 227-yo‘l bo‘limi boshlig‘i</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2000-2001 yy. - Nurota tumani yo‘l xo‘jaligi pudrat tamirlash-foydalanish davlat korxonasi
                                225-yo‘l bo‘lim boshlig‘i
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2001-2004 yy. - Nurota tumani yo‘l xo‘jaligi pudrat tamirlash-foydalanish davlat korxonasi
                                “Arnasoy” suv ombori 1-to‘g‘on qurilishi bo‘lim boshlig‘i
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2004-2008 yy. - Nurota tumani yo‘l xo‘jaligi pudrat tamirlash-foydalanish davlat korxonasi
                                225-yo‘l bo‘limi boshlig‘i
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2008-2013 yy. - Nurota tumani yo‘l xo‘jaligi pudrat tamirlash-foydalanish korxonasi bosh
                                muhandisi
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2013-2015 yy. - Nurota tumani hokimining kapital qurilish, kommunikatsiyalar, kommunal
                                xo‘jalik va obodonlashtirish masalalari bo‘yicha birinchi o‘rinbosari
                            </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2015 -2017 yy.- Navoiy viloyat yo‘llar boshqarmasi boshlig‘ining birinchi o‘rinbosari</p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2017 y. - h.v     - Nurota tumani yullardan foydalanish davlat muassasasi direktori </p>

                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle14;
