import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle12 = () => {

    const _speech = new SpeechSynthesisUtterance();

    const speak = (event) => {
        if (window.isSpeakingEnabled) {
            _speech.text = event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);
        }
    }
    return (
        <section className="portfolio_details_area sec_pad" style={{maxHeight: "3000px", minHeight: "1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft: "8%"}} className="col-lg-8 ">
                <img style={{height: 250, width: 200, float: 'right'}} className="img-fluid mb_20"
                     src={require('../../img/teams/mintaqaBoshliq.jpg')} alt=""/>
                <div onClick={speak} className="row">
                    <div>
                        <div className="portfolio_details_info pr_50">
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">Исмоилов Севдиёр Мухитдин ўғли</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2024 йил 6 августдан:
                                “Навоий минтақавий йўлларга буюртмачи хизмати”
                                давлат муассасаси директори



                            </p>
                            <span>{<br/>}</span>
                            <h5 className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2017-2020 йй. - “Навоий минтақавий йўлларга буюртмачи хизмати”  давлат унитар корхонасининг Техник кузатиш ва бажарилган ишларни қабул қилиш бўлими бош мутахассиси </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>
                                2020-2024 йй. -  “Навоий минтақавий йўлларга буюртмачи хизмати”  давлат муассасаси бош муҳандиси в.в.б </p>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize: '20px', color: 'black'}}>2024 й. -  ҳ.в.  - “Навоий минтақавий йўлларга буюртмачи хизмати”  давлат муассасаси  директори
                            </p>

                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle12;
