import axios from "axios";
import {API_URL} from "../Constants";


class GalleryVideoService {

    getVideoPage(p,s)
    {
        return axios.get(`${API_URL}/api/gallery/videos`,{params:{page:p,size:s}})
    }
    getAllVideos()
    {
        return axios.get(`${API_URL}/api/gallery/videos/all`)
    }

}
export default new GalleryVideoService()
