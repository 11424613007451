import React, {Component} from 'react';
import Isotope from 'isotope-layout/js/isotope';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import Portfolio2griditem from './Portfolio2griditem';
import GalleryPictureService from "../../api/GalleryPictureService";
import {Link} from "react-router-dom";

class Portfolio2grid extends Component{

    constructor() {
        super();
        this.state={
            galleryPictures:[]
        }
    }

    componentDidMount() {

        GalleryPictureService.getAllFatherGalleryPictures().then(response=>{
            this.setState({galleryPictures:response.data})
        })

    }


    render(){
        return(
            <section className="portfolio_area sec_pad bg_color">
                <div className="container">
                    <div  className="row portfolio_gallery mb_30" id="work-portfolio">
                        {this.state.galleryPictures.map(
                            (p,i)=>

                                <Portfolio2griditem  colClass="col-lg-6" pImg={p.minioImageId} ptitle={p.description} family={p.family} />

                        )}
                    </div>
                </div>
            </section>
        )
    }
}
export default Portfolio2grid;
