import React, {useEffect} from 'react';
import CustomNavbar from '../../components/CustomNavbar';
import Breadcrumb from '../../components/Breadcrumb';

import Partner from '../../components/Partner';

import {useTranslation} from "react-i18next";
import Footer from '../../components/Footer/Footer';
import FooterData from "../../components/Footer/FooterData";
import {useLocation} from "react-router-dom";

import FileServiceUstav from "../../components/Service/Sservice/FileServiceUstav";
import AllLeftMessagesService from "../../components/Service/Sservice/AllLeftMessagesService";

const AllLeftMessages = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle={t('korxonaU')} /*Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"*//>
            <AllLeftMessagesService/>
            {/*<AgencyAbout ServiceData={ServiceData}/>*/}
            <Partner pClass="partner_logo_area_five bg_color"/>
            <Footer FooterData={FooterData}/>

        </div>
    )
}
export default AllLeftMessages;
