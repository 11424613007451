import React, { Component } from "react";
import DigitalMarketing from "./Pages/Digital-marketing";
import PaymentProcessing from "./Pages/Payment-processing";
import HRManagement from "./Pages/HR-Management";
import Startup from "./Pages/Startup";
import HomeCRM from "./Pages/Home-CRM";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Process from "./Pages/Process";
import Team from "./Pages/Team";
import Portfolio2col from "./Pages/Portfolio-2col";
import Portfolio3col from "./Pages/Portfolio-3col";
import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
import PortfolioSingle from "./Pages/PortfolioSingle";
import PortfolioSingle4 from "./Pages/PortfolioSingle4";
import Bloglist from "./Pages/Bloglist";
import BlogSingle from "./Pages/BlogSingle";
import Contact from "./Pages/Contact";
import ScrollToTopRoute from "./ScrollToTopRoute";
import Landing from "./Pages/Landing";
import homesupport from "./Pages/home-support";
import homeERP from "./Pages/Home-ERP";
import homeHosting from "./Pages/HomeHosting";
import homeSecurity from "./Pages/HomeSecurity";
import homeSoftwareDark from "./Pages/Home-software-dark";
import HomeAppShowcase from "./Pages/Home-app-showcase";
import HomeCloud from "./Pages/Home-cloud";
import HomeTracking from "./Pages/Home-tracking";
import HomeEvent from "./Pages/Home-event";
import HomeChat from "./Pages/Home-chat";
import Price from "./Pages/Price";
import Faq from "./Pages/Faq";
import ServiceDetails from "./Pages/ServiceDetails";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import BlogGridPage from "./Pages/BlogGridPage";
import NotFound from "./Pages/404";
import AfterRegistration from "./Pages/AfterRegistration";
import SuccessfulRegistration from "./Pages/SuccessfulRegistration";

import failedRegistration from "./Pages/failedRegistration";
import Home from "./Pages/Home";
import { Router} from 'react-router'
import { Switch,Route  } from "react-router-dom";

import PasswordResetForm from "./components/PasswordResetForm";
import PasswordResetForm2 from "./components/PasswordResetFormStep2";
import AfterPasswordResetRequest from "./Pages/AfterPasswordResetRequest";
import SuccessfulPasswordReset from "./Pages/SuccessfulPasswordReset";
import NewsFullPage from "./Pages/News/NewsFullPage";
import AuthorityList from "./Pages/AuthorityList";
import PortfolioSingle2 from "./Pages/PortfolioSingle2";
import PortfolioSingle3 from "./Pages/PortfolioSingle3";
import Settings from "./Pages/Settings";
import history from "./index";
import VideoGallery from "./Pages/VideoGallery";
import Branch from "./Pages/Branch";
import Content from "./Content"

import colorContrast from 'color-contrast'
import MuhimFaktlar from "./Pages/Xisobotlar/MuhimFaktlar";
import MoliyaviyNatijalar from "./Pages/Xisobotlar/MoliyaviyNatijalar";
import Xaridlar from "./Pages/Xisobotlar/Xaridlar";
import BuxgalteriyaBalansi from "./Pages/Xisobotlar/BuxglteriyaBalansi";
import BushIshUrinlari from "./Pages/Xisobotlar/BushIshUrinlari";
import Failed from "./Pages/failed";
import AuthenticatedRoute from './components/AuthenticatedRoute'
import Search from "./Pages/Search";
import SearchResults from "./Pages/SearchResults";
import Ustav from "./Pages/Xisobotlar/Ustav";
import Strorg from "./Pages/Xisobotlar/Strorg";
import UstavTaqsimlanishi from "./Pages/Xisobotlar/UstavTaqsimlanishi";
import AsosiyYunalishVaMaqsad from "./Pages/Xisobotlar/AsosiyYunalishVaMaqsad";
import BajarilganIshlar from "./Pages/Xisobotlar/BajarilganIshlar";
import AllLeftMessagesService from "./components/Service/Sservice/AllLeftMessagesService";
import AllLeftMessages from "./Pages/Xisobotlar/AllLeftMessages";
import AnsweredMessages from "./Pages/Xisobotlar/AnsweredMessages";
import TeamRegion from "./Pages/TeamRegion";
import PortfolioSingle6 from "./Pages/PortfolioSingle6";
import PortfolioSingle7 from "./Pages/PortfolioSingle7";
import PortfolioSingle8 from "./Pages/PortfolioSingle8";
import PortfolioSingle9 from "./Pages/PortfolioSingle9";
import PortfolioSingle10 from "./Pages/PortfolioSingle10";
import PortfolioSingle11 from "./Pages/PortfolioSingle11";
import PortfolioSingle12 from "./Pages/PortfolioSingle12";
import PortfolioSingle13 from "./Pages/PortfolioSingle13";
import PortfolioSingle14 from "./Pages/PortfolioSingle14";
import PortfolioSingle15 from "./Pages/PortfolioSingle15";
import PortfolioSingle16 from "./Pages/PortfolioSingle16";
import PortfolioSingle20 from "./Pages/PortfolioSingle20";
import PortfolioSingle21 from "./Pages/PortfolioSingle21";


class App extends Component {
  componentDidMount() {
    this.props.hideLoader();

  }
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route  path={"/"} exact component={Home}/>
          {/*<ScrollToTopRoute path="/Digital-marketing" component={DigitalMarketing}/>*/}
          {/*<ScrollToTopRoute path="/Payment-processing" component={PaymentProcessing}/>*/}
          {/*<ScrollToTopRoute path="/HR-Management" component={HRManagement}/>*/}
          {/*<ScrollToTopRoute path="/Startup" component={Startup}/>*/}
          {/*<ScrollToTopRoute path="/Home-CRM" component={HomeCRM}/>*/}
          <Route path="/About" component={About}/>
          <Route path="/importantf" component={MuhimFaktlar}/>
          <Route path="/financialr" component={MoliyaviyNatijalar}/>
          <Route path="/purchases" component={Xaridlar}/>
          <Route path="/balancesh" component={BuxgalteriyaBalansi}/>
          <Route path="/strorg" component={Strorg}/>

          <Route path="/vacancy" component={BushIshUrinlari}/>
          <Route path="/ustav" component={Ustav}/>
          <Route path="/ustavd" component={UstavTaqsimlanishi}/>
          <Route path="/fields" component={AsosiyYunalishVaMaqsad}/>
          <Route path="/performedw" component={BajarilganIshlar}/>
          <Route path="/user/messages/all" component={AllLeftMessages}/>
          <Route path="/user/messages/answered" component={AnsweredMessages}/>
          <Route path="/verify" component={Content}/>
          <Route path="/Branch" component={Branch}/>
          <Route path="/VideoGallery" component={VideoGallery}/>
          {/*<Route path="/Service" component={Service}/>*/}
          {/*<Route path="/Process" component={Process}/>*/}
          <Route path="/Team" component={Team}/>
          <Route path="/TeamRegion" component={TeamRegion}/>
          <AuthenticatedRoute path="/UserSettings" component={Settings}/>
          <Route path="/search" component={Search}/>
          <Route path="/searchresults" component={Price}/>
          <Route path="/Portfolio-2col" component={Portfolio2col}/>
          <Route path="/Portfolio-3col" component={Portfolio3col}/>
          <Route path="/Portfolio-fullwidth-4col"component={Portfoliofull4col}/>
          <Route path="/PortfolioSingle" component={PortfolioSingle}/>
          <Route path="/PortfolioSingle2" component={PortfolioSingle2}/>
          <Route path="/PortfolioSingle3" component={PortfolioSingle3}/>
          <Route path="/PortfolioSingle6" component={PortfolioSingle6}/>
          <Route path="/PortfolioSingle7" component={PortfolioSingle7}/>
          <Route path="/PortfolioSingle8" component={PortfolioSingle8}/>
          <Route path="/PortfolioSingle9" component={PortfolioSingle9}/>
          <Route path="/PortfolioSingle10" component={PortfolioSingle10}/>
          <Route path="/PortfolioSingle11" component={PortfolioSingle11}/>
          <Route path="/PortfolioSingle12" component={PortfolioSingle12}/>
          <Route path="/PortfolioSingle13" component={PortfolioSingle13}/>
          <Route path="/PortfolioSingle14" component={PortfolioSingle14}/>
          <Route path="/PortfolioSingle15" component={PortfolioSingle15}/>
          <Route path="/PortfolioSingle16" component={PortfolioSingle16}/>
          <Route path="/PortfolioSingle20" component={PortfolioSingle20}/>
          <Route path="/PortfolioSingle21" component={PortfolioSingle21}/>
          <Route path="/GalleryPicture" component={PortfolioSingle4}/>
          {/*<ScrollToTopRoute path="/Bloglist" component={Bloglist}/>*/}
          <ScrollToTopRoute path="/BlogSingle" component={BlogSingle}/>
          {/*<ScrollToTopRoute path="/BlogGridPage" component={BlogGridPage}/>*/}
          <Route path="/Contact" component={Contact}/>
          {/*<ScrollToTopRoute path="/Landing" component={Landing}/>*/}
          {/*<ScrollToTopRoute path="/home-support" component={homesupport}/>*/}
          {/*<ScrollToTopRoute path="/HomeHosting" component={homeHosting}/>*/}
          {/*<ScrollToTopRoute path="/Home-ERP" component={homeERP}/>*/}
          {/*<ScrollToTopRoute path="/HomeSecurity" component={homeSecurity}/>*/}
          {/*<ScrollToTopRoute path="/HomeAppShowcase" component={HomeAppShowcase} />*/}
          {/*<ScrollToTopRoute path="/Home-software-dark" component={homeSoftwareDark}/>*/}
          {/*<ScrollToTopRoute path="/Home-cloud" component={HomeCloud}/>*/}
          {/*<ScrollToTopRoute path="/Home-Tracking" component={HomeTracking}/>*/}
          {/*<ScrollToTopRoute path="/Home-event" component={HomeEvent}/>*/}
          {/*<ScrollToTopRoute path="/Home-chat" component={HomeChat}/>*/}

          <Route path="/Faq" component={Faq}/>
          {/*<Route path="/ServiceDetails" component={ServiceDetails}/>*/}
          <Route path="/SignIn" component={SignIn}/>
          <Route path="/SignUp" component={SignUp}/>
          {/*<Route path="/Authority" component={AuthorityList}/>*/}
          <Route path="/AfterRegistration" component={AfterRegistration}/>
          <Route path="/AfterPasswordResetRequest" component={AfterPasswordResetRequest}/>
          <Route path="/success" component={SuccessfulRegistration}/>
          <Route path="/successpasswordreset" component={SuccessfulPasswordReset}/>
          <Route path="/fail" component={Failed}/>
          <Route path="/forgot-password" component={PasswordResetForm}/>
          <Route path="/resetpassword" component={PasswordResetForm2}/>
          <Route path="/news" component={NewsFullPage}/>
          <Route path="/error" component={failedRegistration}/>
          <Route component={NotFound}/>
        </Switch>
      </Router>
    );
  }
}

export default App;

