import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';

import Footer from "../components/Footer/Footer";
import {useTranslation} from "react-i18next";
const Failed = () => {

    const _speech = new SpeechSynthesisUtterance();

    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }
    const { t, i18n } = useTranslation();
    return(
  <div className="body_wrapper">
    <CustomNavbar hbtnClass="new_btn" />
    <section className="error_two_area">
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/error.png")} alt="" />
          <h2 onClick={speak}>{t('havolaMO')}</h2>
          <p onClick={speak}>
              {t('boshSO')} ...{" "}
          </p>
          <form action="#" className="search">
            <input type="text" className="form-control" placeholder="search" />
          </form>
          <a  onClick={speak} href="/" className="about_btn btn_hover">
              {t('boshSQ')} <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
    <Footer FooterData={FooterData} />
  </div>
);}
export default Failed;
