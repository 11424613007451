import React, {useEffect, useState} from 'react';
import SeoTitle from '../Title/SeoTitle';
import Fade from 'react-reveal/Fade';
import {useTranslation} from "react-i18next";
import Schedule from "../../Pages/Event/Schedule";
const Service = () => {

    const _speech = new SpeechSynthesisUtterance();
     const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }

    const { t, i18n } = useTranslation();
    return(
        <React.Fragment>
            <section className="seo_service_area sec_pad">
                <div onClick={speak} id="grey" className="container "   >
                    <SeoTitle  Title={t('yullarHaqida')}/>
                    <div className="seo_fact_info">
                        <div className="seo_fact_item">
                            <div className="text">
                                <div className="counter one">9474</div>
                                <p>{t('umumiy')}</p>
                            </div>
                        </div>
                        <div className="seo_fact_item">
                            <div className="text">
                                <div className="counter two">4017</div>
                                <p>{t('foydalanishdagi')}</p>
                            </div>
                        </div>
                        <div className="seo_fact_item">
                            <div className="text">
                                <div className="counter three">5457</div>
                                <p>{t('ichki')}</p>
                            </div>
                        </div>
                        <div className="seo_fact_item last">
                            <div className="text">
                                <div className="counter four">297.5</div>
                                <p>{t('rejadagiTamirlash')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Schedule/>
            <section className="seo_features_one sec_pad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="seo_features_img seo_features_img_two">
                                <div className="round_circle"></div>
                                <div className="round_circle two"></div>
                                {/*<img style={{height:"450px",width:"600px"}} src={require('../../img/seo/features_img_two.png')} alt=""/>*/}

                                <video src={require('../../video/8.mp4')} style={{height:"450px",width:"550px"}} controls="controls" autoPlay={false}/>
                            </div>
                        </div>
                        <div className="col-lg-6 d-flex align-items-center">
                            <Fade bottom cascade>
                                <div className="seo_features_content">
                                    <h2 onClick={speak}className="wow fadeInUp">Axborot 24</h2>
                                    <h6 onClick={speak}className="wow fadeInUp">{t('axborot24')}</h6>
                                    {/*<p className="wow fadeInUp">Bu yerga ma'lumot qo'yiladi 04</p>*/}
                                    {/*<a href=".#" className="seo_btn seo_btn_one btn_hover wow fadeInUp">Ko'proq bilish</a>*/}
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service;
