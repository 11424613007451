import axios from 'axios';
import {API_URL} from '../Constants'
import history from "../index";

export const USER_NAME_SESSION_ATTRIBUTE_NAME='authenticatedUser'
let headers=new Headers();
headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
class AllRoadsForBranchService {

    getAll()
    {
        return axios.get(`${API_URL}/api/allroadsforbranch`)
    }


}
export default new AllRoadsForBranchService()
