import React, {useEffect} from 'react';
import CustomNavbar from '../components/CustomNavbar';
import Breadcrumb from '../components/Breadcrumb';
import FaqSection from '../components/FaqSection';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import {useLocation} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";

const AfterRegistration = () => {
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu ml-auto mr-auto"/>
            <Breadcrumb  breadcrumbClass="breadcrumb_area" imgName="breadcrumb/banner_bg.png" Ptitle={t('royxatdanUY')} Pdescription={t('afterR')}/>

        </div>
    )
}
export default AfterRegistration;
