import React from 'react';
import Text from "react-loading-screen/dist/components/Text";
import Blogrightsidebar3 from "../Blog/Blogrightsidebar3";

const Portfoliosingle =()=>{

    const _speech = new SpeechSynthesisUtterance();


    const speak=(event)=>{
        if(window.isSpeakingEnabled)
        {
            _speech.text =event.currentTarget.textContent;
            _speech.lang = 'ru-RU'
            window.speechSynthesis.speak(_speech);}
    }


    return(
        <section className="portfolio_details_area sec_pad" style={{maxHeight:"3000px",minHeight:"1000px"}}>
            <div className="col-lg-3"><Blogrightsidebar3/></div>
            <div style={{marginLeft:"8%"}} className="col-lg-8 " >
                <img  style={{ height: 250,width:200,float:'right'}}className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>
                <div className="row" >
                    <div  >
                        <div onClick={speak}className="portfolio_details_info pr_50">
                            <h5 onClick={speak} className="f_700 f_p f_size_20 t_color3 mb-30">Камолов Эркин Шамсиддинович</h5>
                            <p onClick={speak}className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} >2019 йил 4 мартдан:
                                Навоий вилояти автомобиль йўллари ҳудудий бош бошқармаси бошлиғи
                            </p>
                            <span>{<br/>}</span>
                            <h5 onClick={speak}className="f_700 f_p f_size_20 t_color3 mb-30">МЕҲНАТ ФАОЛИЯТИ</h5>
                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>
                                2005-2009 йй. - Тошкент автомобиль ва йўллар институти талабаси</p>
                                <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} > 2009-2011 йй. - Навоий шаҳар кон металлургия комбинати Зарафшон қурилиш бошқармаси Зармитан трест майдони иш юритувчиси</p>
                                    <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} >2011-2012 йй. - Кармана туман йўл хўжалиги пудрат таъмирлаш фойдаланиш корхонаси 219-йўл бўлими бошлиғи</p>
                                        <p className="f_400 f_size_15 mb-0"style={{fontSize:'20px',color:'black'}} > 2012-2014 йй. - Кармана туман йўл хўжалиги пудрат таъмирлаш, фойдаланиш корхонаси Автомобил йўлларидан фойдаланиш бўлими бошлиғи</p>
                                            <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2014-2014 йй. - Ўзбекистон Республикаси Молия Вазирлиги ҳузуридаги Республика йўл жамғармаси ижрочи дирекцияси Автомобил йўлларини қуриш, қайта қуриш ва таъмирлашни техник кузатиш бўлими етакчи мутахасиси</p>
                                                <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2014-2018 йй. - Кармана туман йўллардан фойдаланиш унитар корхонаси директори</p>
                                                    <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}> 2018-2019 йй. - Навоий йўл қуриш-таъмирлаш унитар корхонаси директори</p>
                                                        <p className="f_400 f_size_15 mb-0" style={{fontSize:'20px',color:'black'}}>  2019 й. -  ҳ.в.  - Навоий вилояти автомобиль йўллари бош бошқармаси бошлиғи</p>




                        </div>

                    </div>
                    {/*<div className="col-lg-4">*/}
                    {/*    <div className="portfolio_details_gallery">*/}
                    {/*        <img className="img-fluid mb_20" src={require('../../img/teams/boshliq.jpg')} alt=""/>*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details2.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details3.jpg')} alt=""/>*!/*/}
                    {/*        /!*<img className="img-fluid mb_20" src={require('../../img/portfolio-details/pr_details4.jpg')} alt=""/>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </div>

        </section>
    )
}
export default Portfoliosingle;
