import axios from "axios";
import {API_URL} from "../Constants";


class NewsService {
    getAll()
    {
        return axios.get(`${API_URL}/api/branches`)
    }
    getById(id)
    {
        return axios.get(`${API_URL}/api/branches/${id}`)
    }

}
export default new NewsService()
