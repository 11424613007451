import axios from "axios";
import {API_URL} from "../Constants";


class CurrencyRatesService {

    getCity()
    {
        return axios.get('https://extreme-ip-lookup.com/json/')

    }}
export default new CurrencyRatesService()
