import React, {Component} from 'react';
import Stserviceitems from './StserviceItem';
import Reveal from 'react-reveal/Reveal';
import { Link } from "react-router-dom";
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import Pdf from '../../../Documents/KR_2020_12_molnat.pdf';
import Pdf2 from '../../../Documents/KR_2021_06_molnat.pdf';
import  FileSaver  from 'file-saver';
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Row,
    Table,
} from "reactstrap";

import FeatherIcon from "feather-icons-react";
import Blogrightsidebar from "../../Blog/Blogrightsidebar";
import {withTranslation} from "react-i18next";
import Blogrightsidebar3 from "../../Blog/Blogrightsidebar3";
import Blogrightsidebar4 from "../../Blog/Blogrightsidebar4";
import DocFilesService from "../../../api/DocFilesService";
import axios from "axios";
import {API_URL} from "../../../Constants";
import FileDownload from "js-file-download";
class FileServiceMoliyaviyNatijalar extends Component{
    constructor() {
        super();
        this.state={
            files:[]
        }
    }

    componentDidMount() {
        DocFilesService.getDocFilesList('TYPE_MOLNAT').then((res)=>
        {
            this.setState({
                files:res.data
            })

        })

    }
    viewPDF=(file)=>{

        DocFilesService.openFile(file.id.year,file.id.chorak,file.id.locale,file.id.typ).then((response)=>{
            const file = new Blob([response.data], { type: "application/pdf" });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        })


    }

    download=(file)=>{

        DocFilesService.downloadFileF(file.id.year,file.id.chorak,file.id.locale,file.id.typ)

            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob.data]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    blob.headers['filename'],
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

    }

     render(){
        return(
            <section className="agency_service_area bg_color" style={{marginBottom:"10px"}}>
                <div>
                    <div style={{float:"left",width:"20%",marginRight:"5%"}}>
                        <Blogrightsidebar4 />
                    </div>
                    <div style={{float:"right",width:"70%",marginRight:"5%",marginTop:"70px"}} >

                        <div className="table-responsive bg-white shadow rounded mt-4">
                            <Table className="mb-0 table-center">
                                <thead className="bg-light">
                                <tr>
                                    <th scope="col" style={{ minWidth: "300px" }}>
                                        {this.props.t('dokumentN')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        style={{ maxWidth: "150px" }}
                                    >
                                        {this.props.t('hajmi')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        style={{ width: "100px" }}
                                    >

                                    </th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        style={{ width: "100px" }}
                                    >

                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.files.map((f,k)=><tr key={k}>
                                    <td>
                                        <div className="media">
                                            <FeatherIcon icon="file" className="fea icon-ex-md"/>
                                            <div className="content ml-3" style={{fontSize: "14px"}}>
                                                <Link
                                                    to="forums-topic"
                                                    className="forum-title text-primary font-weight-bold"
                                                >
                                                    {f.name}
                                                </Link>
                                                <p className="text-muted small mb-0 mt-2">

                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center small h6">{f.docFileLocation.size}</td>
                                    <td className="text-center small">
                                        <button className="btn small btn-primary" onClick={()=>this.viewPDF(f)}
                                                style={{fontSize: "10px"}}> {this.props.t('korish')}</button>
                                    </td>
                                    <td className="text-center small">
                                        <button className="btn small btn-primary"
                                                style={{fontSize: "10px"}} onClick={()=>this.download(f)}>{this.props.t('yuklash')}</button>
                                    </td>
                                </tr>)}




                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(FileServiceMoliyaviyNatijalar);
